<script>
  import { navigate } from "svelte-navigator";
  import TextInput from "../../components/Form/TextInput.svelte";
  import { sendForgotPasswordEmail } from "../../helpers/apiHelpers/user-management.rest.svelte";
  import { translator } from "../../store";

  async function handleSubmit(e) {
    try {
      const resetFormData = new FormData(e.target);
      await sendForgotPasswordEmail(resetFormData);
      /* let saveResp = null;
      if (!saveResp.id) {
        throw new RequestError("Bilinmeyen Hata #o4Wi", {
          message: "Bilinmeyen Hata #o4Wi",
        });
      }
      window.scrollTo({ top: 0 });
      navigate(-1);
      toast.success(
        translator("Başarılı"),
        formType === "edit"
          ? translator("Değişiklikler Kaydedildi")
          : translator("Kullanıcı oluşturuldu")
      ); */
    } catch (err) {
      // toast.error(translator("Hata"), translator(err.getResponseMessage()));
    }
  }

  function onBackClick() {
    navigate("/");
  }
</script>

<form id="forgot_password-form" on:submit|preventDefault={handleSubmit}>
  <div class="mb-3">
    <TextInput
      fieldName="email-address"
      label={translator("Email adresi")}
      required
    />
  </div>
  <div class="text-center">
    <button
      type="submit"
      form="forgot_password-form"
      class="btn btn-primary btn-block"
    >
      {translator("Mail Gönder")}
    </button>
  </div>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    on:click={onBackClick}
    style="cursor: pointer; float: right; margin: 8px;"
  >
    {translator("Geri Dön")}
  </div>
</form>
