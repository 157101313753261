<script>
  import ApexCharts from "apexcharts";
  import { translator } from "../../store";
  export let series;
  export let xaxis;
  export let yaxis;
  export let stroke;
  export let markers;
  export let chartType = "area";
  export let chartHeight = 400;
  export let colors = ["#ec6464"];
  export let failText = '';
  export let stacked = false;
  let chart = null;
  let uniqueID = Math.random().toString(36).slice(2, 12);
  // TODO :: action buttons z-index

  $: if (series) {
    const options = {
      stacked,
      chart: {
        height: chartHeight,
        type: chartType,
      },
      colors: colors,
      dataLabels: {
        enabled: false,
      },
      series: [...series],
    };
    if (xaxis) {
      options.xaxis = xaxis;
    }
    if (yaxis) {
      options.yaxis = yaxis;
    }
    if (stroke) {
      options.stroke = stroke;
    }
    if (markers) {
      options.markers = markers;
    }
    if (!chart) {
      chart = new ApexCharts(document.getElementById(uniqueID), { ...options });
      chart.render();
    } else {
      chart.resetSeries();
      chart.updateSeries([...series]);
    }
  }
</script>

<div id={uniqueID} />
{#if !series}
  <div class="d-flex justify-content-center align-items-center">
    {translator(failText)}
  </div>
{/if}
