<script>
    import Portal from "../components/Portal/index.svelte";
    import Loading from "../components/Loading/index.svelte";

    export let loading = false;

    const portalStyles = {
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        top: 0,
        backgroundColor: 'rgba(255,255,255,1)',
        zIndex: '999999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
</script>
{#if loading}
    <Portal styles={portalStyles}>
        <Loading />
    </Portal>
{/if}