<script>
  export let dataSource;
  export let columns;
</script>
<div class="table-responsive">
  <table class="table table-striped table-bordered table-responsive-sm">
      <thead>
          <tr style="font-size:1rem !important">
            {#each columns as column}
              {#if column.style}
                <th style={column.style}>{column.title}</th>
              {:else}
                <th>{column.title}</th>
              {/if}
            {/each}
          </tr>
      </thead>
      <tbody style="font-size:1rem !important">
          {#each dataSource as row}
            <tr>
              {#each columns as column}
                {#if column.style}
                  <td style={column.style}>{row[column.data]}</td>
                {:else}
                  <td>{row[column.data]}</td>
                {/if}
              {/each}
            </tr>
          {/each}
      </tbody>
  </table>
</div>
