<script>
  import { onMount } from "svelte";
  import { getDevice } from "../../helpers/apiHelpers/index.svelte";
  import { Card, Tabs } from "../../components/index.svelte";
  import DeviceAlarm from "./DeviceAlarm.svelte";
  import DeviceNotifiers from "./DeviceNotifiers.svelte";
  import translate from "../../store/translation.store";
  import WetTempGraphs from "./WetTempGraphs.svelte";
  import VibrationGraphs from "./VibrationGraphs.svelte";
  import MeasurementTables from "./MeasurementTables.svelte";
  import { checkIfAdmin, isUserType } from "../../helpers/utils.svelte";
  import { userStore } from "../../store/index";

  export let deviceID = null;
  let deviceDetails = null;
  let isAdmin = checkIfAdmin($userStore?.user?.user_type);
  let isCompanyAdmin = isUserType($userStore?.user?.user_type, "COMPANY_ADMIN");
  let tabConfig = {
    wetTempGraphs: {
      tabTitle: translate('Sıcaklık/Nem Grafikleri'),
      component: WetTempGraphs,
      componentProps: {
        alarmValues: deviceDetails?.alarm_values,
        deviceID
      }
    },
    vibrationGraphs: {
      tabTitle: translate('Titreşim Grafikleri'),
      component: VibrationGraphs,
      componentProps: {
        deviceID
      }
    },
    measurementTables: {
      tabTitle: translate('Ölçüm Tabloları'),
      component: MeasurementTables,
      componentProps: {
        deviceID
      }
    },
  };
  
  if (isAdmin || isCompanyAdmin) {
    tabConfig.alarm = {
      tabTitle: translate('Cihaz konfigurasyonu'),
      component: DeviceAlarm,
      componentProps: {
        alarmValues: deviceDetails?.alarm_values,
        deviceID
      }
    };
    tabConfig.notifiers = {
      tabTitle: translate('Bildirim Alıcıları'),
      component: DeviceNotifiers,
      componentProps: {
        deviceID
      }
    }
  }
  onMount(async () => {
    try {
      deviceDetails = await getDevice(deviceID);
    } catch (error) {
      console.log('Err => ', error);
    }
  })

</script>
<div>
  <Card
    headerText={`"${deviceDetails?.name}" Cihazının Detayları Görüntüleniyor`}
  >
  <Tabs configuration={tabConfig}/>
  </Card>
</div>
