<script>
  import select2 from "select2";
  import "./select2-overrides.css";
  select2();
  import { onMount } from "svelte";
  import { translator } from "../../../store";

  export let label = "";
  export let required = false;
  export let value = "";
  export let fieldName = "";
  export let placeholder = "";
  export let disabled = false;

  export let options;
  export let valueField;
  export let labelField;
  export let onChange;

  let inputID = Math.random().toString(36).slice(2, 12);
  let select2Instance;
  onMount(() => {
    select2Instance = window.jQuery(`#${inputID}`).select2({
      placeholder,
      selectionCssClass: 'select2-custom-container',
      language: {
        noResults: function () {
          return translator("Filtreye uygun seçenek bulunamadı");
        },
        search: function() {
          return translator("Filtrele")
        },
        errorLoading: function() {
          return translator("Seçenekler yüklenemedi")
        }
      }
    });
    if (value) {
      select2Instance.val(value).trigger("change");
    }
    if (typeof onChange === 'function') {
      select2Instance.on('select2:select', onChange);
    }
  });
  $: {
    if (select2Instance) {
      select2Instance.val(value).trigger("change");
    }
  }
</script>
<label class="select2-label w-100" for={inputID}>
  {#if label}
    <div class="mb-2">
      {label}
      {#if required}
        <span class="text-red">*</span>
      {/if}
    </div>
  {/if}

  <select
    name={fieldName}
    class="select2-with-label-single js-states d-block form-control"
    id={inputID}
    disabled={disabled}
  >
    {#if placeholder}
      <option />
    {/if}
    {#each (options || []) as option}
      <option value={option[valueField]}>
        {Array.isArray(labelField)
          ? labelField.map((f) => option[f]).join(" ")
          : option[labelField]}
      </option>
    {/each}
  </select>
</label>

