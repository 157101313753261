<script>
    import { onMount, onDestroy } from 'svelte'
    let ref
    let portal
    export let styles = {};
    export let className = ''
    onMount(() => {
      portal = document.createElement('div')
      portal.id = "portal-" + Math.random().toString(16).slice(2);
      if (Object.keys(styles).length) {
        for (const property in styles) {
          portal.style[property] = styles[property];
        }
      }
      if (className) {
        portal.classList.add(...className.split(' '));
      }
      document.body.appendChild(portal)
      portal.appendChild(ref)
    })
    
    onDestroy(() => {
      document.body.removeChild(portal)
    })
    
</script>
<div bind:this={ref}>
  <slot />
</div>