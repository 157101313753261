<script>
  import { userStore } from "../../store";
  export let requireSuperAdmin = false
  let requiredUserType = requireSuperAdmin ? 'SYSTEM_ADMIN' : 'ADMIN';
  let isAdmin = $userStore?.user?.user_type === requiredUserType;
  console.log("USER TYPEE", $userStore?.user?.user_type);
  if ($userStore?.user?.user_type === 'SYSTEM_ADMIN') {
    isAdmin = true;
  }
</script>
{#if isAdmin}
  <slot></slot>
{/if}
