<script>
  import { navigate } from "svelte-navigator";
  import { onMount } from "svelte";
  import {
    Card,
    TextInput,
    Button,
    Autocomplete,
  } from "../../components/index.svelte";
  import { translator, loading } from "../../store";

  import {
    getCompanies
  } from "../../helpers/apiHelpers/index.svelte";

  import { toast, RequestError, CITIES_IN_TURKEY } from "../../helpers/utils.svelte";
  import { USER_TYPES } from "../../helpers/constants.svelte";
  import { createUser, getUser, updateUser } from "../../helpers/apiHelpers/user-management.rest.svelte";

  export let userID = null;
  export let formType = null;
  export let userType = 'COMPANY_USER';
  const userTypes = Object.entries(USER_TYPES).map(([key, value]) => ({ value: key, label: value }));

  let userMethods = {
    getter: getUser,
    creator: createUser,
    updater: updateUser,
  };
  let companies = [];
  let selectedUserType = userType;

  let userDetails = null;
  let passwordRequirements = {
    min8Chars: {
      label: translator("En az 8 karakter"),
      valid: false
    },
    containUppercase: {
      label: translator("En az 1 büyük harf"),
      valid: false
    },
    containLowercase: {
      label: translator("En az 1 küçük harf"),
      valid: false
    },
    containDigit: {
      label: translator("En az 1 rakam"),
      valid: false
    },
    containSpecialChar: {
      label: translator("En az 1 özel karakter"),
      valid: false
    },
  };
  if (formType === "edit") {
    passwordRequirements = null;
  }

  function getFormTitle(formType, userType) {
    let formTitle = "";
    switch (true) {
      case formType === "new" && userType === "system-admins":
        formTitle = "Yeni Kullanici";
        break;
      case formType === "edit" && userType === "system-admins":
        formTitle = "Kullaniciyi Düzenle";
        break;
      default:
        break;
    }
    return translator(formTitle);
  }

  function handlePasswordChange(e) {
    if (!e) {
      e = "";
    }
    passwordRequirements.containSpecialChar.valid = e.match(/\W|_/g)?.length > 0;
    passwordRequirements.containDigit.valid = e.match(/\d/g)?.length > 0;
    passwordRequirements.containLowercase.valid = e.match(/[a-z]/g)?.length > 0;
    passwordRequirements.containUppercase.valid = e.match(/[A-Z]/g)?.length > 0;
    passwordRequirements.min8Chars.valid = e.length >= 8;
  }

  function isPasswordValid(passwordRequirements) {
    let isValid = true;
    if (passwordRequirements) {
      Object.entries(passwordRequirements).forEach((r1) => {
        if (!r1[1].valid) {
          isValid = false;
        }
      });
    }
    console.log('isvlia',isValid);
    return isValid;
  }

  onMount(async () => {
    try {
      $loading = true;
      companies = await getCompanies();
      if (formType === "edit" && userID) {
        let userResponse = await userMethods.getter(userID);
        userDetails = userResponse.user;
        selectedUserType = userDetails?.user_type
      }
      $loading = false;
    } catch (err) {
      toast.error(translator("Hata"), translator(err.getResponseMessage()));
    }
  });

  async function handleSubmit(e) {
    const userFormData = new FormData(e.target);
    try {
      let saveResp = null;
      if (formType === "edit" && userID) {
        saveResp = await userMethods.updater(userID, userFormData);
      } else {
        saveResp = await userMethods.creator(userFormData);
      }
      if (!saveResp.id) {
        throw new RequestError("Bilinmeyen Hata #o4Wi", {
          message: "Bilinmeyen Hata #o4Wi",
        });
      }
      window.scrollTo({ top: 0 });
      navigate(-1);
      toast.success(
        translator("Başarılı"),
        formType === "edit"
          ? translator("Değişiklikler Kaydedildi")
          : translator("Kullanıcı oluşturuldu")
      );
    } catch (err) {
      toast.error(translator("Hata"), translator(err.getResponseMessage()));
    }
  }
</script>

<Card headerText={getFormTitle(formType, userType)}>
  <form
    class="basic-form"
    id="user-form"
    on:submit|preventDefault={handleSubmit}
  >
    <div class="row">
      <div class="col-md-6 p-2">
        <TextInput
          fieldName="firstname"
          label="Ad"
          required
          value={userDetails?.firstname}
        />
      </div>
      <div class="col-md-6 p-2">
        <TextInput
          fieldName="lastname"
          label="Soyad"
          required
          value={userDetails?.lastname}
        />
      </div>
      <div class="col-md-6 p-2">
        <Autocomplete
          onChange={(e) => {selectedUserType = e.target.value}}
          options={userTypes}
          labelField='label'
          valueField='value'
          fieldName='user_type'
          label={translator('Kulanici Tipi')}
          value={userType}
          required
        />
      </div>
      <div class="col-md-6 p-2">
        <Autocomplete
          options={companies}
          labelField={['short_name', 'name']}
          valueField='id'
          label='Sirketler'
          value={userDetails?.companies && userDetails?.companies[0]?.id}
          fieldName="company_ids"
          required
        />
      </div>
      <div class="col-md-6 p-2">
        <TextInput
          fieldName="email"
          label="E-mail"
          required
          value={userDetails?.email}
          type="email"
        />
      </div>
      <div class="col-md-6 p-2">
        <TextInput
          fieldName="username"
          label={translator('Kullanici Adi')}
          value={userDetails?.username}
          required
          type="text"
        />
      </div>
      {#if selectedUserType === 'COMPANY_USER'}
        <div class="col-md-12 p-2">
          <Autocomplete
            fieldName="city"
            options={CITIES_IN_TURKEY}
            valueField='city_code'
            labelField='city_name'
            placeholder={translator('Şehir Seçiniz')}
            label={translator('Şehir')}
            value={userDetails?.city}
          />
        </div>
      {/if}
      {#if formType === "new"}
        <div class="col-md-12 p-2">
          <TextInput
            fieldName="password"
            label="Şifre"
            required
            type="password"
            onChange={handlePasswordChange}
          />
          <div class="p-4">
            <ul class="list-group">
              {#each Object.keys(passwordRequirements) as requirement}
                <li class="list-group-item d-flex justify-content-between align-items-center"
                    class:text-red={!passwordRequirements[requirement].valid}
                    class:text-green={passwordRequirements[requirement].valid}
                    >
                  {passwordRequirements[requirement].label}
                </li>
              {/each}
          </ul>
          </div>
        </div>
      {/if}
    </div>
    <hr />
    <div class="gap-2 d-flex justify-content-end align-items-center">
      <Button formName="user-form" colorCls="btn-success" disabled={!isPasswordValid(passwordRequirements)}>
        {translator("Kaydet")}
      </Button>
      <Button onClick={() => navigate(-1)} colorCls="btn-dark">
        {translator("Vazgeç")}
      </Button>
    </div>
  </form>
</Card>
