<script>
  import { fade, scale } from "svelte/transition";

  let modalOutside;
  export let visible = false;
  let keyupListener = function (e) {
    if (e.keyCode === 27) {
      toggle();
    }
  };
  let outsideClickListener = function (e) {
    if (e.target === modalOutside) {
      toggle();
    }
  };

  export let title = "";
  export let onClose = null;
  export function toggle() {
    visible = !visible;
    if (visible) {
      document.addEventListener("keyup", keyupListener);
      document.addEventListener("click", outsideClickListener);
    } else {
      document.removeEventListener("keyup", keyupListener);
      document.addEventListener("click", outsideClickListener);
      if (typeof onClose === "function") {
        setTimeout(onClose, 0);
      }
    }
  }
</script>

{#if visible}
  <div
    bind:this={modalOutside}
    style="background-color: rgba(24, 24, 24, 0.3)"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    transition:fade={{ duration: 150 }}
    class:show={visible}
    class:d-block={visible}
  >
    <div in:scale out:fade>
      <div class="modal-dialog modal-lg">
        <div class="modal-content" style="max-height: 90vh;">
          <div class="modal-header">
            <slot name="modal-title">
              <h5 class="modal-title">
                {title}
              </h5>
            </slot>
            <button
              type="button"
              class="btn-close"
              on:click={toggle}
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body" style="overflow:auto;">
            <slot name="modal-body" />
          </div>
          {#if $$slots["modal-footer"]}
            <div class="modal-footer">
              <slot name="modal-footer" />
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}
