<script>
  import { fade } from "svelte/transition";
  import Loading from "../components/Loading/index.svelte";
  import { loading } from "../store";
</script>
<div style="position: relative;">
{#if $loading}
    <div out:fade={{ duration: 300 }} class="loading-content">
      <Loading />
    </div>
{/if}
<slot />
</div>

<style>
  .loading-content {
    background-color: #fcfcfc;
    z-index: 999999;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    position: absolute;
    height: 100%;
  }
</style>
