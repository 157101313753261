<script>
  import { onMount } from "svelte";
  import ZoomableLineChart from "../../components/Chart/ZoomableLineChart.svelte";
  import { MultipleSelect } from "../../components/index.svelte";
  import {
    getDeviceVibrationMeasurementsByDate,
    getDeviceMeasurementDates,
    getDeviceMeasurementSamples

  } from "../../helpers/apiHelpers/index.svelte";
  import DatePicker from "../../components/Form/DatePicker.svelte";
  import { start } from "@popperjs/core";

  export let deviceID = null;
  let measurementDates = [];
  let measurementSamples = [];
  let selectedDate;
  const today = new Date();
  let startDateValue = today.toISOString().split('T')[0];

  onMount(async () => {
    measurementDates = await getDeviceMeasurementDates(deviceID);
    measurementSamples = await getDeviceMeasurementSamples(deviceID);
    selectedDate = measurementDates[0];
    console.log("ststst", startDateValue);
  });

  const onDateSelect = (newDate) => {
    selectedDate = newDate;
    console.log("dateChanged: ", selectedDate);
  }

  const onStartDateChange = async (event) => {
    startDateValue = event.target.value;
    console.log("ststst", startDateValue);
    measurementDates = await getDeviceMeasurementDates(deviceID, startDateValue);
  }

  const getVibrationSeries = (seriesName, selectedDate) => {
    return async () => {
      const data = await getDeviceVibrationMeasurementsByDate(deviceID, seriesName, selectedDate);
      return data.series;
    }
  }

  const getMeasurementSamplesForSeries = (seriesName) => {
    if (!measurementSamples) {
      return null;
    }
    measurementSamples[seriesName] = measurementSamples[seriesName].slice(0, 51);
    return measurementSamples[seriesName].map((i1, index) => {
      return {x: index * 10, y: i1};
    });
  }
  const xAxisFormetter = (value) => {
    if (value % 50 === 0) {
      return value;
    }
    return "";
  }
</script>
<div style="padding-top: 16px">
  <DatePicker
  label="Başlangıç Tarihi"
  fieldName="start_date"
  value={startDateValue}
  onChange={onStartDateChange}
  />
  <MultipleSelect
    label="Tarih Seçiniz"
    value={selectedDate}
    onChange={onDateSelect}
    placeholder=""
    selectOptions={measurementDates}
  />
</div>
{#if selectedDate}

  <div>
    <div class="card-header">
      <h4 class="card-title">X Ekseni Grafiği</h4>
    </div>
    {#key (selectedDate + "gx")}
      <ZoomableLineChart
        xAxisFormetter={xAxisFormetter}
        queryFn={getVibrationSeries("gx", selectedDate)}
        sampleData={getMeasurementSamplesForSeries("gx")}
        sampleColor="#000000"
        lineColor="#429EA6"
        key='Gx'
      />
    {/key}
  </div>
  <div>
    <div class="card-header">
      <h4 class="card-title">Y Ekseni Grafiği</h4>
    </div>
    {#key (selectedDate + "gy")}
      <ZoomableLineChart
        xAxisFormetter={xAxisFormetter}
        queryFn={getVibrationSeries("gy", selectedDate)}
        sampleData={getMeasurementSamplesForSeries("gy")}
        lineColor="#3F88C5"
        sampleColor="#000000"
        key='Gy'
      />
    {/key}
  </div>
  <div>
    <div class="card-header">
      <h4 class="card-title">Z Ekseni Grafiği</h4>
    </div>
    {#key (selectedDate + "gz")}
      <ZoomableLineChart
        xAxisFormetter={xAxisFormetter}
        queryFn={getVibrationSeries("gz", selectedDate)}
        sampleData={getMeasurementSamplesForSeries("gz")}
        lineColor="#F49D37"
        sampleColor="#000000"
        key='Gz'
      />
    {/key}
  </div>
{/if}
