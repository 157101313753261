<script>
  import { navigate } from "svelte-navigator";
  import { onMount } from "svelte";
  import {
    Card,
    TextInput,
    Button,
    Autocomplete,
  } from "../../components/index.svelte";
  import { translator } from "../../store";

  import { toast, RequestError, CITIES_IN_TURKEY } from "../../helpers/utils.svelte";
  import { getCompanies } from "../../helpers/apiHelpers/company-management.rest.svelte";
  import translate from "../../store/translation.store";
  import { createDevice, getDevice, updateDevice } from "../../helpers/apiHelpers/device-management.rest.svelte";

  export let deviceID = null;
  export let formType = null;
  let isEdit = Boolean(formType === 'edit' && deviceID);
  let deviceDetails;
  let companies = [];

  onMount(async () => {
    try {
      if (isEdit) {
        deviceDetails = await getDevice(deviceID);
      }
      companies = await getCompanies();
    } catch (error) {
      toast.error(translate('Hata'), 'Bilinmeyen Hata #a2bD');
    }
  })

  const handleSubmit = async (e) => {
    const formData = new FormData(e.target);
    try {
      let saveResp = null;
      if (isEdit) {
        saveResp = await updateDevice(deviceID, formData);
      } else {
        saveResp = await createDevice(formData)
      }
      if (!saveResp.id) {
        throw new RequestError("Bilinmeyen Hata #o4Wi", {
          message: "Bilinmeyen Hata #o4Wi",
        });
      }
      window.scrollTo({ top: 0 });
      navigate(-1);
      toast.success(
        translator("Başarılı"),
        formType === "edit"
          ? translator("Değişiklikler Kaydedildi")
          : translator("Cihaz oluşturuldu")
      );
    } catch (err) {
      console.log(err);
      toast.error(translator("Hata"), err.getResponseMessage());
    }
  }
</script>

<Card headerText={'New Device'}>
  <form
    class="basic-form"
    id="user-form"
    on:submit|preventDefault={handleSubmit}
  >
    <div class="row">
      <div class="col-md-6 p-2">
        <TextInput
          fieldName="name"
          label={translate('Cihaz Adı')}
          required
          value={deviceDetails?.name}
        />
      </div>
      <div class="col-md-6 p-2">
        {#if companies && companies.length > 0}
          <Autocomplete
            fieldName="company_id"
            options={companies}
            valueField='id'
            labelField='name'
            placeholder={translate('Şirket Seçiniz')}
            label={translate('Şirketler')}
            value={deviceDetails?.company_id}
          />
        {/if}
      </div>
      <div class="col-md-6 p-2">
        <Autocomplete
          fieldName="city_name"
          options={CITIES_IN_TURKEY}
          valueField='city_code'
          labelField='city_name'
          placeholder={translate('Şehir Seçiniz')}
          label={translate('Şehirler')}
          value={deviceDetails?.city_name}
        />
      </div>
      <div class="col-md-6 p-2">
        <Autocomplete
          fieldName="run_mode"
          options={[
            { id: 1, mode_title: "Sürekli" },
            { id: 2, mode_title: "15 Dakika" },
            { id: 3, mode_title: "1 Saat" },
            { id: 4, mode_title: "1 Gün" }
          ]}
          valueField='id'
          labelField='mode_title'
          placeholder={translate('Çalışma Modu Seçiniz')}
          label={translate('Çalışma Modu')}
          value={deviceDetails?.run_mode}
        />
      </div>
      <div class="col-md-6 p-2">
        <TextInput
          fieldName="remote_id"
          type="number"
          label={translate('Cihaz Seri Numarası')}
          required
          value={deviceDetails?.remote_id}
        />
      </div>
    </div>
    <hr />
    <div class="gap-2 d-flex justify-content-end align-items-center">
      <Button formName="user-form" colorCls="btn-success">
        {translator("Kaydet")}
      </Button>
      <Button onClick={() => navigate(-1)} colorCls="btn-dark">
        {translator("Vazgeç")}
      </Button>
    </div>
  </form>
</Card>
