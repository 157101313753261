<script>
    import { backInOut } from 'svelte/easing';
    import { fly } from "svelte/transition";
    export let visible = false;
    export let message = null;
    export let type = 'error';
    export let title = '';
    export let onClose = null;

    let iconTypes = {
        success: 'mdi mdi-check-circle-outline',
        info: 'mdi mdi-email-alert',
        error: 'mdi mdi-alert'
    }

    let types = {
        error: 'red'
    }

</script>
{#if visible}
    <div transition:fly={{ easing: backInOut, x: 100 }} class="alert alert-{types[type] || type} left-icon-big alert-dismissible fade show">
        <button on:click={onClose} type="button" class="btn-close" aria-label="btn-close"><span><i class="mdi mdi-btn-close"></i></span>
        </button>
        <div class="media">
            <div class="alert-left-icon-big">
                <span><i class={iconTypes[type]}></i></span>
            </div>
            <div class="media-body">
              {#if title}
                <h6 class="mt-1 mb-2">{title}</h6>
              {/if}
              {#if message}
                <p class="mb-0">{message}</p>
              {/if}
            </div>
        </div>
    </div>
{/if}
