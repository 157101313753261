<script>
  import { onMount } from 'svelte';
  import TimeAgo from 'javascript-time-ago';
  import tr from 'javascript-time-ago/locale/tr';
  import { getUnresolvedNotifications, resolveNotification } from '../../helpers/apiHelpers/index.svelte';
  import { translator, loading } from "../../store";
  import { toast } from "../../helpers/utils.svelte";
  import { DataTable } from '../../components/index.svelte';

  TimeAgo.addDefaultLocale(tr);
  const timeAgo = new TimeAgo('tr-TR');

  let dataSource;

  onMount(async () => {
    try {
      $loading = true;
      dataSource = await getUnresolvedNotifications();
      console.log("NONONON", dataSource);
      $loading = false;
    } catch (err) {
      toast.error(translator("Hata"), translator(err.getResponseMessage()));
    }
  });

  async function onResolve(notificationRow) {
    try {
      $loading = true;
      await resolveNotification(notificationRow.id);
      dataSource = await getUnresolvedNotifications();
      $loading = false;
      toast.success(translator("Başarılı"), translator("Bildirim görüldü olarak işaretlendi"));
    } catch (err) {
      toast.error(translator("Hata"), translator(err.getResponseMessage()));
    }
  }

  function onDelete() {

  }

  function getColumns() {
    return [
      { data: "device_name", title: translator("İlgili Cihaz") },
      { data: "notification_text", title: translator("Mesaj") },
      {
        data: "created_at",
        title: translator("Süre"),
        render: (text) => timeAgo.format(new Date(text), 'round-minute')
      },
      { data: "resolved_at", title: translator("Görülme Tarihi") },
      { data: "resolved_by", title: translator("Görevli") },
    ]
  }

</script>
<div>
  <DataTable
    title={translator("Bildirimler")}
    eventHandlers={{
      onResolve,
    }}
    columns={getColumns()}
    bind:dataSource
  />
</div>
