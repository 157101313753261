<script>
  export let label = "";
  export let required = false;
  export let value = "";
  export let type = "text";
  export let fieldName = "";
  export let placeholder = "";
  export let onChange = null;
  export let step = null;
  export let disabled = false;
  export let min = undefined;
  export let max = undefined;
  let inputID = Math.random().toString(36).slice(2, 12);

  function setType(element) {
      element.type = type;
  }
  
  function handleChange(e) {
      if (typeof onChange === 'function') {
          onChange(value, e);
      }
  }

</script>
<style>
  .disabled-text-input{
    pointer-events:none;
    background:#E0E0E0;
  }
</style>
{#if label}
  <label class="form-label" for={inputID}>
    {label}
    {#if required}
        <span class="text-red">*</span>
    {/if}
  </label>
{/if}
<input 
  class="form-control"
  class:disabled-text-input={disabled}
  {placeholder}
  name={fieldName}
  id={inputID}
  {step}
  {required}
  bind:value
  {min}
  {max}
  use:setType
  on:input={handleChange}
  on:change={handleChange}
/>
