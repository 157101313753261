<script context="module">
  import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

  export async function createCompany(companyData){
      return await fetchWithHeaders(getBaseURL() + '/companies', {
          method: 'POST',
          body: companyData
      });
  };

  export async function getCompany(companyID){
      return await fetchWithHeaders(getBaseURL() + '/companies/' + companyID);
  };

  export async function updateCompany(companyID, companyData){
      return await fetchWithHeaders(getBaseURL() + '/companies/' + companyID + '/update', {
          method: 'POST',
          body: companyData
      });
  };

  export async function deleteCompany(companyID){
      return await fetchWithHeaders(getBaseURL() + '/companies/' + companyID, {
          method: 'DELETE'
      });
  };

  export async function getCompanies(forSelect){
      const queryParams = {};
      if (forSelect) {
          queryParams.forSelect = 1;
      }
      return await fetchWithHeaders(querifiedURL(getBaseURL() + '/companies', queryParams));
  };
</script>
