<script>
  import { Route, Router } from "svelte-navigator";
  import { userStore } from "./store";
  import {
    AdminOnly,
    Content,
    Header,
    Sidebar,
  } from "./components/index.svelte";
  import {
    UserList,
    UserDetails,
    UserForm,
    AuthLayout,
    CompanyList,
    CompanyForm,
    CompanyDetails,
    DeviceList,
    DeviceDetails,
    DeviceForm,
    MainDashboard,
    DeviceAverageCompare
  } from "./views/index.svelte";
  import { fade } from "svelte/transition";

</script>

<Router>
  {#if $userStore?.isLoggedIn}
    <Header />
    <Sidebar />
    <Content>
      <Router>
        <Route
          path="/"
          component={MainDashboard}
        />
        <AdminOnly>
          <Route path="/company-management/*">
            <Route path="companies">
              <div in:fade>
                <CompanyList />
              </div>
            </Route>
            <Route path="companies/new" let:params>
              <div in:fade>
                <CompanyForm formType="new" />
              </div>
            </Route>
            <Route path="companies/:companyID" let:params>
              <CompanyDetails companyID={params.companyID} />
            </Route>
            <Route path="companies/:companyID/edit" let:params>
              <div in:fade>
                <CompanyForm
                  companyID={params.companyID}
                  formType="edit"
                />
              </div>
            </Route>
          </Route>
          <Route path="/user-management/*">
            <Route path="users">
              <div in:fade>
                <UserList />
              </div>
            </Route>
            <Route path="users/new" let:params>
              <div in:fade>
                <UserForm formType="new" />
              </div>
            </Route>
            <Route path="users/:userID" let:params>
              <UserDetails userID={params.userID} />
            </Route>
            <Route path="users/:userID/edit" let:params>
              <div in:fade>
                <UserForm
                  userID={params.userID}
                  formType="edit"
                />
              </div>
            </Route>
          </Route>
        </AdminOnly>
        <Route path="/device-management/*">
          <Route path="average-compare" let:params>
            <div in:fade>
              <DeviceAverageCompare />
            </div>
          </Route>
          <Route path="devices">
            <div in:fade>
              <DeviceList />
            </div>
          </Route>
          <Route path="devices/new" let:params>
            <div in:fade>
              <DeviceForm formType="new" />
            </div>
          </Route>
          <Route path="devices/:deviceID" let:params>
            <DeviceDetails deviceID={params.deviceID} />
          </Route>
          <Route path="devices/:deviceID/edit" let:params>
            <div in:fade>
              <DeviceForm
                deviceID={params.deviceID}
                formType="edit"
              />
            </div>
          </Route>
        </Route>
      </Router>
    </Content>
  {:else}
    <Route path="/">
      <AuthLayout formType="login" />
    </Route>
    <Route path="/forgot-password">
      <AuthLayout formType="forgotPassword" />
    </Route>
    <Route path="/reset-password">
      <AuthLayout formType="resetPassword" />
    </Route>
  {/if}
</Router>
