<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";

  import { translator } from "../../store/index";
  import { deleteUser, getUsers } from "../../helpers/apiHelpers/index.svelte";
  import { DataTable, Modal, Button } from "../../components/index.svelte";
  import { toast } from "../../helpers/utils.svelte";
  export let listType = "property-owners";
  let dataSource = null;
  let toggler;
  let userToDelete = null;

  function getColumns() {
    if (listType === "system-admins") {
      return [
        { data: "firstname", title: translator("Ad") },
        { data: "lastname", title: translator("Soyad") },
        { data: "email", title: translator("Email") },
        {
          data: "is_active",
          title: translator("Kullanıcı Durumu"),
          render: (text) => {
            if (text === 1) {
              return translator("Aktif");
            }
            return translator("Pasif");
          },
        },
      ];
    }
    return [
      { data: "firstname", title: translator("Ad") },
      { data: "lastname", title: translator("Soyad") },
      { data: "email", title: translator("Email") },
    ];
  }

  function getDataSource() {
    return getUsers();
  }

  function onView(row) {
    console.log(row);
    // navigate(window.location.pathname + '/' + row.id);
  }

  function onEdit(row) {
    navigate(window.location.pathname + "/" + row.id + "/edit");
  }

  function onDelete(row) {
    userToDelete = row;
    toggler();
  }

  async function onDeleteClick(userID) {
    try {
      await deleteUser(userID);
      dataSource = await getDataSource();
      toast.success(
        translator("Başarılı"),
        translator('"__FULLNAME__" Kullanıcısı silindi', {
          __FULLNAME__: `${userToDelete.firstname} ${userToDelete.lastname}`,
        })
      );
      toggler();
    } catch (err) {
      console.log(err);
      toast.error(
        translator("Hata"),
        err.getResponseMessage ? err.getResponseMessage() : err.message
      );
    }
  }

  function onAdd() {
    navigate(window.location.pathname + "/new");
  }

  onMount(async () => {
    dataSource = await getDataSource();
  });
</script>

<div class="row">
  <DataTable
    title={translator("Kullanıcılar")}
    eventHandlers={{
      onEdit,
      onDelete,
    }}
    columns={getColumns()}
    onAddClick={onAdd}
    addText={translator("Yeni Kullanıcı Oluştur")}
    bind:dataSource
  />
</div>
<Modal
  bind:toggle={toggler}
  title={translator("Dikkat!")}
  onClose={() => {
    userToDelete = null;
  }}
>
  <div slot="modal-body">
    {translator(
      '"__FULLNAME__" Kullanıcısını kalıcı olarak silmek istediğinize emin misiniz?',
      {
        __FULLNAME__: `${userToDelete.firstname} ${userToDelete.lastname}`,
      }
    )}
  </div>
  <div slot="modal-footer">
    <Button size="sm" onClick={() => onDeleteClick(userToDelete.id)}>
      {translator("Sil")}
    </Button>
    <Button size="sm" onClick={toggler} colorCls="btn-dark">
      {translator("Vazgeç")}
    </Button>
  </div>
</Modal>
