<script>
  import { navigate } from "svelte-navigator";
  import TextInput from "../../components/Form/TextInput.svelte";
  import { Button, Modal } from "../../components/index.svelte";
  import translate from "../../store/translation.store";
  import {
    setDeviceAlarms,
    createDeviceMeasurementSamples
  } from "../../helpers/apiHelpers/index.svelte";

  export let deviceID = null;
  export let alarmValues = null;

  let sampleModalToggle;
  let modalTitle;
  let modalContent;

  const handleSubmit = async (e) => {
    const formData = new FormData(e.target);
    try {
      await setDeviceAlarms(deviceID, formData);
    } catch (error) {
      console.log({ error });
    }
  };
</script>

<form
  class="basic-form"
  id="company-form"
  on:submit|preventDefault={handleSubmit}
>
  <div class="row">
    <div class="col-md-6 p-2">
      <TextInput
        fieldName="sd_alarm"
        label={translate("SD Alarm")}
        value={alarmValues?.sd_alarm}
        type="text"
      />
    </div>
    <div class="col-md-6 p-2">
      <TextInput
        fieldName="rms_alarm"
        label="RMS"
        value={alarmValues?.rms_alarm}
      />
    </div>
    <div class="col-md-6 p-2">
      <TextInput
        fieldName="peak_alarm"
        label={translate("PEAK Alarm")}
        value={alarmValues?.peak_alarm}
        type="text"
      />
    </div>
    <div class="col-md-6 p-2">
      <TextInput
        fieldName="crest_alarm"
        label={translate("CREST Alarm")}
        value={alarmValues?.crest_alarm}
        type="text"
      />
    </div>
    <div class="col-md-6 p-2">
      <TextInput
        fieldName="speed_alarm"
        label={translate("SPEED Alarm")}
        value={alarmValues?.speed_alarm}
        type="text"
      />
    </div>
    <div class="col-md-6 p-2">
      <TextInput
        fieldName="temp_alarm"
        label={translate("Temp Alarm")}
        value={alarmValues?.temp_alarm}
        type="text"
      />
    </div>
    <div class="col-md-6 p-2">
      <TextInput
        fieldName="wet_alarm"
        label={translate("Wet Alarm")}
        value={alarmValues?.wet_alarm}
        type="text"
      />
    </div>
    <div class="col-md-12 p-2">
      <TextInput
        fieldName="freq_warning_multiplier"
        label={translate("Frequency Warning Multiplier")}
        value={alarmValues?.freq_warning_multiplier}
        type="text"
      />
    </div>
    <div class="col-md-12 p-2">
      <Button fullWidth="1" colorCls="btn-info" onClick={ async () => {
        try {
          const createResponse = await createDeviceMeasurementSamples(deviceID); 
          modalTitle = "Başarılı";
          modalContent = "Örneklem oluşturuldu";
        } catch (e) {
          modalTitle = "Hata";
          modalContent = e?.getResponseMessage ? e.getResponseMessage() : e.message;
        }
        sampleModalToggle();
      }}>
        {translate("Örneklem Oluştur")}
      </Button>
    </div>
  </div>
  <hr />
  <div class="gap-2 d-flex justify-content-end align-items-center">
    <Button formName="company-form" colorCls="btn-success">
      {translate("Kaydet")}
    </Button>
    <Button onClick={() => navigate(-1)} colorCls="btn-dark">
      {translate("Vazgeç")}
    </Button>
  </div>
</form>
<Modal
  bind:toggle={sampleModalToggle}
  title={translate(modalTitle)}
>
  <div slot="modal-body">
    {translate(modalContent)}
  </div>
  <div slot="modal-footer">
    <Button size="sm" onClick={sampleModalToggle} colorCls="btn-dark">
      {translate("Tamam")}
    </Button>
  </div>
</Modal>
