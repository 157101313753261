<script>
    import Portal from '../../components/Portal/index.svelte';
    import ToastHandler from './ToastHandler.svelte';
    import { notification, userStore } from '../../store/index.js';
    let portalStyles = {
        top: '16px',
        zIndex: '10',
        right: '21px'
    };
    $: {
        portalStyles.top = $userStore?.isLoggedIn ? '90px' : '16px'; // TODO :: Top is not updating right-after logging in
    }
</script>
<div style="height: 0px;">
    {#if $notification.toasts.length}
        <Portal className='position-fixed' styles={{...portalStyles}}>
            {#each $notification.toasts as toast}
                <ToastHandler {...toast} />
            {/each}
        </Portal>
    {/if}
</div>
