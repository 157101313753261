<script>
    import { onMount } from "svelte";
    import CustomToast from "../../components/CustomToast/index.svelte";
    
    export let message;
    export let type = null;
    export let timeOut = 3000;
    export let title;
    let visible = true;

    const onClose = () => {
        visible = false;
    }

    onMount(() => {
        if (visible) {
            setTimeout(onClose, timeOut);
        }
    })
</script>
<CustomToast
    visible={visible}
    type={type}
    message={message}
    onClose={onClose}
    title={title}
/>
