<script>
  export let headerText = "";
  export let addText ="";
  export let onAddClick = null;
</script>

<div class="card">
  {#if headerText}
    <div class="card-header">
      <h4 class="card-title">{headerText}</h4>
      {#if addText && typeof onAddClick === 'function'}
          <button type="button" class="btn btn-success btn-sm" on:click={onAddClick}>
            {addText}
            <span class="btn-icon-end">
              <i class="fa fa-plus" />
            </span>
          </button>
      {:else}
        <slot name="card-actions"></slot>
      {/if}
    </div>
  {/if}
  <div class="card-body">
    <slot />
  </div>
</div>
