<script>
  export let colorCls = "btn-primary";
  export let formName = "";
  export let size = "";
  export let onClick = undefined;
  export let fullWidth = false;
  export let disabled = false;
  export let className = '';
  let type = "";
  if (formName) {
    type = "submit"
  }
</script>
<button disabled={disabled ? 'disabled' : ''} type={type} class={`btn ${size ? ['btn-',size].join('') : ''} ${colorCls} ${fullWidth ? 'w-100': ''} ${className}`} form={formName} on:click={onClick}>
  <slot/>
</button>
