<script>
  import IconButton from '../IconButton/index.svelte';
  export let row;
  export let eventHandlers;
  const eventToIconProps = {
    onView: {
      iconCls: "fa fa-eye",
      btnColorCls: "btn-info",
    },
    onEdit: {
      iconCls: "fa fa-edit",
      btnColorCls: "btn-warning",
    },
    onDelete: {
      iconCls: "fa fa-trash",
      btnColorCls: "btn-red",
    },
    onRatingClick: {
      iconCls: "fa fa-star",
      btnColorCls: "btn-orange",
    },
    onResolve: {
      iconCls: "fa fa-check",
      btnColorCls: "btn-success",
    },
  };
  let columnWidth = 120;
  $: {
    columnWidth = Object.keys(eventHandlers).length * 40
  }

</script>
<div style={`width:${columnWidth}px;max-width:${columnWidth}px;`} class="d-flex justify-content-between">
  {#each Object.entries(eventHandlers) as [handlerName, fn]}
    <IconButton {...eventToIconProps[handlerName]} onClick={(e) => fn(row)} />
  {/each}
</div>
