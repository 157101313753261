<script>
  export let label = "";
  export let fieldName = "";
  export let value = "";
  export let required = false;
  export let onChange = () => null;
  let inputID = Math.random().toString(36).slice(2, 12);
  $: {
    if (value && typeof value === "string" && value.indexOf(" ") > -1) {
      value = value.split(" ")[0];
    }
  }
</script>

{#if label}
  <label
    class="form-label"
    for={inputID}
  >
    {label}
    {#if required}
      <span class="text-red">*</span>
    {/if}
  </label>
{/if}
<input
  class="form-control"
  name={fieldName}
  id={inputID}
  {required}
  bind:value
  on:change={onChange}
  type="date"
/>
