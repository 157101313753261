<script>
  import { navigate } from "svelte-navigator";
  import { PPPlaceholder } from "../../helpers/utils.svelte";
  import { userStore, locationStore, translator } from "../../store";
  import ProfileDropdown from "../Dropdown/ProfileDropdown.svelte";
  import { USER_TYPES } from "../../helpers/constants.svelte";

  window.jQuery(".dz-scroll").each(function () {
    var scroolWidgetId = window.jQuery(this).attr("id");
    const ps = new PerfectScrollbar("#" + scroolWidgetId, {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20,
    });
    ps.isRtl = false;
  });
</script>

<div class="nav-header">
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <span role="button" on:click={() => navigate('/')} class="brand-logo py-2">
    <img style="max-height: 75%; max-width: 75%" src="/assets/images/app-logo.png" alt="">
  </span>
  <div class="nav-control">
    <div class="hamburger">
      <span class="line" /><span class="line" /><span class="line" />
    </div>
  </div>
</div>
<div class="header">
  <div class="header-content">
    <nav class="navbar navbar-expand">
      <div class="collapse navbar-collapse justify-content-between">
        <div class="header-left">
          <div class="dashboard_bar">{$locationStore}</div>
        </div>
        <ProfileDropdown
          src={$userStore?.user?.pp_file?.url || PPPlaceholder}
          userName={$userStore?.user?.firstname}
          title={translator(USER_TYPES[$userStore?.user?.user_type])}
        />
      </div>
    </nav>
  </div>
</div>
