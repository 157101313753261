<script>
  import { onMount } from "svelte";
  import {
    getDeviceMeasurementTableData
  } from "../../helpers/apiHelpers/index.svelte";
  import { SimpleTable } from "../../components/index.svelte";
  import { translator } from "../../store";

  export let deviceID = null;
  let dataSource = null;
  onMount(async () => {
    const remoteResponse = await getDeviceMeasurementTableData(deviceID);
    if (!remoteResponse || remoteResponse.length === 0) {
      dataSource = [];
    } else {
      dataSource = remoteResponse;
    }
  });
  function getColumns() {
    return [
      { data: "created_at", title: translator("Tarih"), style: "width: 150px; min-width: 150px"},
      { data: "SDx", title: translator("SD (x)")},
      { data: "SDy", title: translator("SD (y)")},
      { data: "SDz", title: translator("SD (z)")},
      { data: "RMSx", title: translator("RMS (x)")},
      { data: "RMSy", title: translator("RMS (y)")},
      { data: "RMSz", title: translator("RMS (z)")},
      { data: "PEAKx", title: translator("Peak (x)")},
      { data: "PEAKy", title: translator("Peak (y)")},
      { data: "PEAKz", title: translator("Peak (z)")},
      { data: "CRESTx", title: translator("Crest (x)")},
      { data: "CRESTy", title: translator("Crest (y)")},
      { data: "CRESTz", title: translator("Crest (z)")},
      { data: "SPEEDx", title: translator("Speed (x)")},
      { data: "SPEEDy", title: translator("Speed (y)")},
      { data: "SPEEDz", title: translator("Speed (z)")},
    ];
  }
</script>
<div style="padding: 16px">
{#if !dataSource}
  Ölçüm değerleri yükleniyor...
{:else if dataSource && dataSource.length === 0}
  Seçilen cihaz için herhangi bir ölçüm değeri bulunmamaktadır.
{:else}
  <SimpleTable
    columns={getColumns()}
    bind:dataSource
  />
{/if}
</div>
