<script>
  export let label = "";
  export let fieldName = "";
  export let value = null;
  export let selectOptions = [];
  export let labelField = null
  export let valueField = null;
  export let placeholder = "Lütfen Seçiniz";
  export let onChange = null;

  const inputID = Math.random().toString(36).slice(2, 12);
  const optionStyle = "height: 30px !important; vertical-align: middle; display: flex; align-items: center; padding: 4px; border-radius: 5px; font-size: 1rem; font-weight: 500"
  $: {
    if (typeof value !== 'string' && value?.toString) {
      value = value.toString();
    }
  }

</script>

{#if label}
  <label class="form-label" for={inputID}>
    {label}
  </label>
{/if}
<select
  class="default-select form-control wide"
  name={fieldName}
  id={inputID}
  style="height: 150px"
  size="2"
  bind:value
  on:change={typeof onChange === 'function' ? (e) => onChange(e.target.value) : null}
>
  {#if placeholder}
    <option style={optionStyle} value="" disabled>{placeholder}</option>
  {/if}
  {#each selectOptions as selectOption}
    <option style={optionStyle} value={valueField ? selectOption[valueField] : selectOption} selected={value === (valueField ? selectOption[valueField] : selectOption)}>{labelField ? selectOption[labelField] : selectOption}</option>
  {/each}
</select>
