const activeLanguage = "tr";

const dictionary = {
  tr: {
    'CLEANING': 'Temizlik',
    'REPAIRING': 'Bakım/Onarım',
    'FIXTURE': 'Demirbaş',
    'GROCERY': 'Market',
    'BILL': 'Aidat/Fatura',
    'LOAN': 'Kredilendirme',
    '[CREATE_API_KEY]::Invalid password': 'Kullanıcı adı veya şifre geçersiz',
    'Token is expired.': 'Oturum süresi doldu. Yeniden giriş yapınız',
    'Day_Sun': 'Paz',
    'Day_Mon': 'Pzt',
    'Day_Tue': 'Sal',
    'Day_Wed': 'Çrş',
    'Day_Thu': 'Prş',
    'Day_Fri': 'Cum',
    'Day_Sat': 'Cmt',
    'January': 'Ocak',
    'February': 'Şubat',
    'March': 'Mart',
    'April': 'Nisan',
    'May': 'Mayıs',
    'June': 'Haziran',
    'July': 'Temmuz',
    'August': 'Ağustos',
    'September': 'Eylül',
    'October': 'Ekim',
    'November': 'Kasım',
    'December': 'Aralık',
  },
  en: {
    'CLEANING': 'Cleaning',
    'REPAIRING': 'Repairing',
    'FIXTURE': 'Fixture',
    'GROCERY': 'Grocery',
    'BILL': 'Dues/Bills',
    'LOAN': 'Loaning',
    '[CREATE_API_KEY]::Invalid password': 'Invalid username or password',
    'Token is expired.': 'Session is expired. Please login again',
    'Day_Sun': 'Sun',
    'Day_Mon': 'Mon',
    'Day_Tue': 'Tue',
    'Day_Wed': 'Wed',
    'Day_Thu': 'Thu',
    'Day_Fri': 'Fri',
    'Day_Sat': 'Sat',
  }
};

const translate = function(str, replaceParams = null) {
  try {
    let translation = dictionary[activeLanguage][str];
    if (!translation) {
      //console.log(`"${activeLanguage}" translation is not found: "${str}"`)
      translation = str;
    }
    if (replaceParams && Object.keys(replaceParams).length > 0) {
      Object.keys(replaceParams).forEach((paramName) => {
        translation = translation.replace(paramName, replaceParams[paramName]);
      })
    }
    return translation;
  } catch(err) {
    console.error(err)
  }
  return str;
};

export default translate;
