<script>
  import DataTable from 'datatables';
  import { onMount } from 'svelte';
  import { translator } from "../../store/index";
  import Card from "../Card/index.svelte"; // To prevent circular dependency import directly
  import ActionColumn from "./ActionColumn.svelte";
  import './main.css';
  DataTable();

  export let title = "";
  export let dataSource = [];
  export let columns;
  export let addText;
  export let onAddClick;
  export let searching = true;
  export let pageLength = 25;
  export let lengthSelect = true;
  export let hiddenHeader = false;
  export let tableID = null;
  export let defaultOrderConfig = null;

  export let eventHandlers = {
    onView: null,
    onEdit: null,
    onDelete: null,
    onRatingClick: null,
    onResolve: null
  };

  const uniqID = Math.random().toString(36).slice(2, 12);

  if (!tableID) {
    tableID = uniqID;
  }
  tableID = tableID + '_dateSortFix1';
  let tableInstance = null;

  if (eventHandlers) {
    Object.keys(eventHandlers).map((handlerType) => {
      if (typeof eventHandlers[handlerType] !== 'function') {
        delete eventHandlers[handlerType];
      }
    })
  }
  if (typeof eventHandlers === 'object' && Object.keys(eventHandlers).length > 0) {
    columns.push({
      title: translator("İşlemler"),
      orderable: false,
      render: function (data, type, row, meta) {
        return `<div data-actid="${meta.row}"></div>`;
      },
    });
  }

  function initializeTable() {
    if (tableInstance) {
      tableInstance.destroy();
      tableInstance = null;
    }
    let dataTableOptions = {
      oLanguage: {
        sLengthMenu: translator("Gösterilecek kayıt sayısı: _MENU_"),
        sInfoEmpty: translator("Gösterilecek veri bulunamadı"),
        sEmptyTable: translator("Gösterilecek veri bulunamadı"),
        sZeroRecords: translator("Filtrelemeye göre eşleşen veri bulunamadı"),
        sSearch: translator("Filtre: "),
        oPaginate: {
          sFirst: translator("İlk Sayfa"),
          sLast: translator("Son Sayfa"),
          sNext: translator("Sonraki"),
          sPrevious: translator("Önceki"),
        },
      },
      data: dataSource,
      columns,
      columnDefs: [{ width: 120, targets: columns.length - 1 }],
      fnDrawCallback: function (oSettings) {
        Array.from(
          document.getElementById(tableID).querySelectorAll("[data-actid]")
        ).forEach((t1) => {
          t1.innerHTML = "";
          new ActionColumn({
            target: t1,
            props: {
              row: dataSource[t1.dataset.actid],
              eventHandlers,
            },
          });
        });
        if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          window
            .jQuery(oSettings.nTableWrapper)
            .find(".dataTables_paginate")
            .hide();
        } else {
          window
            .jQuery(oSettings.nTableWrapper)
            .find(".dataTables_paginate")
            .show();
        }
        // TODO :: ask Utku
        // setTimeout( () => {
        //   window.scrollTo({top: 0, behavior: 'smooth'});
        // }, 5);
      },
      fnInfoCallback: function( oSettings, iStart, iEnd, iMax, iTotal, sPre ) {
        if (iMax === 0) {
          return "";
        }
        let infoText = translator('__MAX__ kayıttan __START__ ile __END__ arasındaki görüntüleniyor')
        infoText = infoText.replace('__MAX__',iMax).replace('__START__',iStart).replace('__END__',iEnd)
        return infoText;
      },
      searching,
      pageLength,
      lengthChange: lengthSelect,
      ext: {
        errMode: "none"
      },
      stateSave: true
    };
    if (defaultOrderConfig) {
      dataTableOptions.order = [defaultOrderConfig];
      dataTableOptions.ordering = true;
    }
    tableInstance = window.jQuery("#" + tableID).DataTable(dataTableOptions);
  }

  onMount( () => {
    initializeTable();
  });

  $: {
    if (dataSource) {
      initializeTable();
    }
  }
</script>

<Card headerText={title} bind:addText bind:onAddClick>
  <div class="table-responsive">
    <table id={tableID} class="display" style="width: 100%" class:hide-header={hiddenHeader} />
  </div>
</Card>
