<script>
  import { navigate } from "svelte-navigator";
  import { onMount } from "svelte";
  import {
    Card,
    TextInput,
    Button,
    FileUpload,
  } from "../../components/index.svelte";
  import { translator, loading } from "../../store";

  import {
    getCompany,
    createCompany,
    updateCompany,
  } from "../../helpers/apiHelpers/index.svelte";

  import { toast, RequestError } from "../../helpers/utils.svelte";

  export let companyID = null;
  export let formType = null;

  let companyDetails = null;

  onMount(async () => {
    try {
      $loading = true;
      if (formType === "edit" && companyID) {
        let companyResponse = await getCompany(companyID);
        companyDetails = companyResponse;
      }
      $loading = false;
    } catch (err) {
      toast.error(translator("Hata"), translator(err.getResponseMessage()));
    }
  });

  async function handleSubmit(e) {
    const companyFormData = new FormData(e.target);
    try {
      let saveResp = null;
      if (formType === "edit" && companyID) {
        saveResp = await updateCompany(companyID, companyFormData);
      } else {
        saveResp = await createCompany(companyFormData);
      }
      if (!saveResp.id) {
        throw new RequestError("Bilinmeyen Hata #o4Wi", {
          message: "Bilinmeyen Hata #o4Wi",
        });
      }
      window.scrollTo({ top: 0 });
      navigate(-1);
      toast.success(
        translator("Başarılı"),
        formType === "edit"
          ? translator("Değişiklikler Kaydedildi")
          : translator("Firma oluşturuldu")
      );
    } catch (err) {
      toast.error(translator("Hata"), translator(err.getResponseMessage()));
    }
  }
</script>

<Card headerText={translator("Yeni Firma Oluştur")}>
  <form
    class="basic-form"
    id="company-form"
    on:submit|preventDefault={handleSubmit}
  >
    <div class="row">
      <div class="col-md-12 p-2">
        <FileUpload
          fieldName="company_logo"
          multiple={false}
          label={translator("Firma Logosu")}
          selectedFiles={companyDetails?.logo_file ? [companyDetails.logo_file] : []}
        />
      </div>
      <div class="col-md-6 p-2">
        <TextInput
          fieldName="name"
          label="Firma İsmi"
          required
          value={companyDetails?.name}
        />
      </div>
      <div class="col-md-6 p-2">
        <TextInput
          fieldName="short_name"
          label="Firma İsim Kısaltması"
          value={companyDetails?.short_name}
        />
      </div>
      <div class="col-md-12 p-2">
        <TextInput
          fieldName="website"
          label="Websitesi"
          value={companyDetails?.website}
          type="text"
        />
      </div>
    </div>
    <hr />
    <div class="gap-2 d-flex justify-content-end align-items-center">
      <Button formName="company-form" colorCls="btn-success">
        {translator("Kaydet")}
      </Button>
      <Button onClick={() => navigate(-1)} colorCls="btn-dark">
        {translator("Vazgeç")}
      </Button>
    </div>
  </form>
</Card>
