<script>
  import { onMount } from "svelte";
  import { getDeviceAverageComparisons } from "../../helpers/apiHelpers/index.svelte";
  import { loading } from "../../store";

  let comparisonData = null;

  const avgLabels = [
    { text: "Sıcaklık", dataKey: "avg_temp" },
    { text: "Nem", dataKey: "avg_wet" },
    { text: "SD", dataKey: "avg_sd" },
    { text: "RMS", dataKey: "avg_rms" },
    { text: "PEAK", dataKey: "avg_peak" },
    { text: "CREST", dataKey: "avg_crest" },
    { text: "SPEED", dataKey: "avg_speed" },
    { text: "100Hz", dataKey: "avg_100" },
    { text: "200Hz", dataKey: "avg_200" },
    { text: "300Hz", dataKey: "avg_300" },
    { text: "400Hz", dataKey: "avg_400" },
    { text: "500Hz", dataKey: "avg_500" },
  ]

  onMount(async () => {
    $loading = true;
    comparisonData = await getDeviceAverageComparisons();
    $loading = false;
  });
</script>

<div class="col-lg-12">
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Ölçüm Değerleri Değişimi</h4>
    </div>
    <div class="card-body">
      {#if !comparisonData}
        <p style="text-align: center; font-weight:bold; font-size: 1.25rem">Karşılaştırma verisi bulunamadı</p>
      {:else}
        <div class="table-responsive">
          <table
            class="table table-bordered table-striped verticle-middle table-responsive-sm"
          >
            <thead>
              <tr>
                <th scope="col" style="width: 200px;">Cihaz Adı</th>
                <th scope="col">{(new Date(comparisonData[0].prev_month)).toLocaleString('tr-TR', {month: 'long', year:'numeric'})}</th>
                <th scope="col">{(new Date(comparisonData[0].current_month)).toLocaleString('tr-TR', {month: 'long', year:'numeric'})}</th>
              </tr>
            </thead>
            <tbody style="font-size: 14px">
              {#each comparisonData as comparison}
                <tr>
                  <td style="color: #0A2342; font-weight:bold">{comparison.device_name}</td>
                  <td style="color: #0A2342">
                    {#each avgLabels as label }
                      <div class="month-formatter" style="display:flex; justify-content: space-between;padding:4px 0px;border-bottom: 1px solid #bababa">
                        <div class="month-label" style="flex:1;">{label.text}:</div>
                        <div class="month-value" style="flex:3; font-weight:bold;">{comparison.prev_avgs != null && comparison.prev_avgs[label.dataKey] || '-'}</div>
                      </div>
                    {/each}
                  </td>
                  <td style="color: #0A2342">
                    {#each avgLabels as label }
                      <div class="month-formatter" style="display:flex; justify-content: space-between;padding:4px 0px;border-bottom: 1px solid #bababa">
                        <div class="month-label" style="flex:1;">{label.text}:</div>
                        <div class="month-value" style="flex:3; font-weight:bold;">{comparison.current_avgs != null && comparison.current_avgs[label.dataKey] || '-'}</div>
                      </div>
                    {/each}
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      {/if}
    </div>
  </div>
</div>
