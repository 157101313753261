import "./helpers/theme-initializer";
import './styles/scss/main.scss';
import './styles/css/mainx.css';
import App from './App.svelte';


new App({
    target: document.body,
    props: {
        name: 'Elma'
    }
})
