<script>
  import { onMount } from "svelte";
  import { Chart } from "../index.svelte";
  import translate from "../../store/translation.store";
  import { get } from "../../helpers/utils.svelte";

  export let queryFn = (f) => f;
  export let sampleData;
  export let constData;
  export let constDataTitle;
  export let key = '';
  export let lineColor;
  export let sampleColor;
  export let xAxisFormetter;
  export let showXLabels = true;
  let dataSource = null;
  let chartData = null;

  onMount(async () => {
    dataSource = await queryFn();
    let series = [
        {
          name: translate(key),
          data: dataSource,
        },
    ];
    if (sampleData && sampleData?.length > 0) {
      series.push({
        name: "Örneklem",
        data: sampleData
      })
    }
    if (constData) {
      series.push({
        name: constDataTitle,
        data: dataSource.map((i1) => {
          return {
            x: i1.x,
            y: constData
          };
        })
      })
    }
    chartData = {
      series,
      xaxis: {
        labels: {
          show: showXLabels,
          formatter: function (value) {
            if (typeof xAxisFormetter === 'function' && value) {
              return xAxisFormetter(value);
            }
            return value;
          },
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            if (value) {
              return value.toFixed(2);
            }
            return value;
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "round",
        width: 2,
      },
    };
  });
</script>

<Chart
  series={chartData?.series}
  xaxis={chartData?.xaxis}
  yaxis={chartData?.yaxis}
  stroke={chartData?.stroke}
  markers={chartData?.markers}
  colors={[lineColor, sampleColor]}
  chartType="line"
/>
