<script>
  import { onMount } from "svelte";
  import { addNewNotifier, deleteDeviceNotifier, getDeviceNotifiers, updateDeviceNotifier } from "../../helpers/apiHelpers/device-management.rest.svelte";
  import {
  AdminOnly,
    Autocomplete,
    Button,
    DataTable,
    Modal,
  } from "../../components/index.svelte";
  import translate from "../../store/translation.store";
  import TextInput from "../../components/Form/TextInput.svelte";

  export let deviceID = null;
  let dataSource = [];
  let toggler;
  let deleteModalToggler;
  let notifier = null;

  const getDataSource = async () => {
    dataSource = await getDeviceNotifiers(deviceID);
  }

  onMount(async () => {
    await getDataSource();
  });

  const onEdit = (row) => {
    notifier = row;
    toggler();
  };

  const onDelete = (row) => {
    notifier = row;
    deleteModalToggler();
  };

  const notificationTypes = [
    { value: "EMAIL", text: "Email" },
    { value: "SMS", text: "SMS" },
    { value: "Call", text: "Sesli Arama" },
  ];

  const columns = [
    { data: "notification_type", title: translate("Bildirim Tipi") },
    { data: "notification_address", title: translate("Alıcı Adresi") },
  ];

  const onDeleteNotifier = async notifierData => {
    await deleteDeviceNotifier(deviceID, JSON.stringify(notifierData));
    await getDataSource();
    deleteModalToggler();
  }

  const onNotifierFormSubmit = async (e) => {
    const formData = new FormData(e.target);
    if (notifier) {
      const json = { notification_type: notifier?.notification_type, old_notification_address: notifier?.notification_address, new_notification_address: formData.get('notification_address') };
      await updateDeviceNotifier(deviceID, JSON.stringify(json));
    } else {
      await addNewNotifier(deviceID, formData);
    }
    await getDataSource();
    toggler();
  };
</script>
<AdminOnly>
  <p>Admin only here</p>
</AdminOnly>
<DataTable
  title={translate("Bildirim Alıcıları")}
  {columns}
  addText={translate("Yeni Alıcı Ekle")}
  onAddClick={toggler}
  eventHandlers={{
    onEdit: onEdit,
    onDelete: onDelete,
  }}
  bind:dataSource
/>
<Modal
  bind:toggle={toggler}
  title={translate(notifier ? "Düzenle" : "Yeni Alıcı")}
  onClose={() => {
    notifier = null;
  }}
>
  <div slot="modal-body">
    <form
      class="basic-form"
      id="notifier-form"
      on:submit|preventDefault={onNotifierFormSubmit}
    >
      <div class="row">
        <div class="col-md-6 p-2">
          <Autocomplete
            label={translate("Bildirme Tipi")}
            options={notificationTypes}
            labelField="text"
            valueField="value"
            value={notifier?.notification_type || "EMAIL"}
            fieldName="notification_type"
            disabled={notifier}
          />
        </div>
        <div class="col-md-6 p-2">
          <TextInput
            label={translate("Alıcı Adresi")}
            value={notifier?.notification_address}
            type="text"
            fieldName="notification_address"
          />
        </div>
      </div>
      <div class="modal-footer">
        <Button formName="notifier-form" size="sm">
          {translate("Kaydet")}
        </Button>
        <Button size="sm" onClick={toggler} colorCls="btn-dark">
          {translate("Vazgeç")}
        </Button>
      </div>
    </form>
  </div>
</Modal>
<Modal
  bind:toggle={deleteModalToggler}
  title={translate('Confirm')}
  onClose={() => {
    notifier = null;
  }}
>
  <div slot="modal-body">
    {translate('Bildirim alıcısını silmek isteidiğinize emin misiniz?')}
  </div>
  <div slot="modal-footer">
    <Button
      size="sm"
      onClick={() => onDeleteNotifier(notifier)}
    >
      {translate('Sil')}
    </Button>
    <Button size="sm" onClick={deleteModalToggler} colorCls="btn-dark">
      {translate('Vazgeç')}
    </Button>
  </div>
</Modal>
