<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";
  
  import { translator, userStore } from "../../store/index";
  import { DataTable, Modal, Button } from "../../components/index.svelte";
  import { checkIfAdmin, toast } from "../../helpers/utils.svelte";
  import { deleteDevice, getDevices } from "../../helpers/apiHelpers/index.svelte";

  let dataSource = null;
  let deleteModalToggler;
  let deviceToDelete = null;
  let isAdmin = checkIfAdmin($userStore?.user?.user_type);

  function getColumns() {
    return [
      { data: "name", title: translator("Cihaz İsmi") },
      { data: "remote_id", title: translator("Seri No") },
      { data: "company_name", title: translator("Şirket İsmi") },
    ];
  }

  function getDataSource() {
    return getDevices();
  }

  function onView(row) {
    navigate(window.location.pathname + '/' + row.id);
  }

  function onEdit(row) {
    navigate(window.location.pathname + "/" + row.id + "/edit");
  }

  function onDelete(row) {
    deviceToDelete = row;
    deleteModalToggler();
  }

  async function onDeleteClick(deviceID) {
    try {
      await deleteDevice(deviceID);
      deleteModalToggler();
      dataSource = await getDataSource();
    } catch (err) {
      console.log(err);
      toast.error(
        translator("Hata"),
        err.getResponseMessage ? err.getResponseMessage() : err.message
      );
    }
  }

  function onAdd() {
    navigate(window.location.pathname + "/new");
  }

  onMount(async () => {
    dataSource = await getDataSource();
  });
</script>

<div class="row">
  <DataTable
    title={translator("Cihazlar")}
    eventHandlers={{
      onView,
      onEdit: isAdmin ? onEdit : null,
      onDelete: isAdmin ? onDelete : null,
    }}
    columns={getColumns()}
    onAddClick={isAdmin ? onAdd : null}
    addText={isAdmin ? translator("Yeni Cihaz Ekle") : null}
    bind:dataSource
  />
</div>
<Modal
  bind:toggle={deleteModalToggler}
  title={translator("Dikkat!")}
  onClose={() => {
    deviceToDelete = null;
  }}
>
  <div slot="modal-body">
    {translator(
      '"__DEVICENAME__" cihazini kalıcı olarak silmek istediğinize emin misiniz?',
      {
        __DEVICENAME__: `${deviceToDelete.name}`,
      }
    )}
  </div>
  <div slot="modal-footer">
    <Button
      size="sm"
      onClick={() => onDeleteClick(deviceToDelete.id)}
    >
      {translator("Sil")}
    </Button>
    <Button size="sm" onClick={deleteModalToggler} colorCls="btn-dark">
      {translator("Vazgeç")}
    </Button>
  </div>
</Modal>
