<script context="module">
  import { fetchWithHeaders, getBaseURL, querifiedURL } from "../utils.svelte";
  export async function createDevice(deviceData) {
    return await fetchWithHeaders(getBaseURL() + "/devices", {
      method: "POST",
      body: deviceData,
    });
  }

  export async function updateDevice(deviceID, deviceData) {
    return await fetchWithHeaders(
      getBaseURL() + `/devices/${deviceID}/update`,
      {
        method: "POST",
        body: deviceData,
      }
    );
  }

  export async function deleteDevice(deviceID) {
    return await fetchWithHeaders(getBaseURL() + `/devices/${deviceID}`, {
      method: "DELETE",
    });
  }

  export async function getDevice(deviceID) {
    return await fetchWithHeaders(getBaseURL() + `/devices/${deviceID}`, {
      method: "GET",
    });
  }

  export async function getDevices() {
    return await fetchWithHeaders(getBaseURL() + "/devices", {
      method: "GET",
    });
  }

  export async function getDeviceMeasurementWithSelectedColumns(
    deviceID,
    requestedColumns = []
  ) {
    const url = new URL(getBaseURL() + `/devices/${deviceID}/measurements`);
    requestedColumns.forEach((column) =>
      url.searchParams.set("requested_columns[]", column)
    );
    return await fetchWithHeaders(url.toString(), {
      method: "GET",
    });
  }

  export async function getDeviceMeasurementDates(
    deviceID,
    beginningDate
  ) {
    const url = new URL(getBaseURL() + `/devices/${deviceID}/measurements/date-list`);
    if (beginningDate) {
      url.searchParams.set("end_date", beginningDate);
    }
    return await fetchWithHeaders(url.toString(), {
      method: "GET",
    });
  }

  export async function getDeviceMeasurementSeriesByType(
    deviceID,
    seriesType
  ) {
    const url = new URL(getBaseURL() + `/devices/${deviceID}/measurements/series`);
    url.searchParams.set("seriesType", seriesType);
    return await fetchWithHeaders(url.toString(), {
      method: "GET",
    });
  }

  export async function getDeviceMeasurement(deviceID, dates) {
    const [startDate, endDate] = dates;
    return await fetchWithHeaders(
      getBaseURL() +
        `/devices/${deviceID}/measurements/summary-table?start_date=${startDate}&end_date=${endDate}`,
      {
        method: "GET",
      }
    );
  }

  export async function setDeviceAlarms(deviceID, deviceData) {
    return await fetchWithHeaders(
      getBaseURL() + `/devices/${deviceID}/alarm_values`,
      {
        method: "POST",
        body: deviceData,
      }
    );
  }

  export async function getDeviceNotifiers(deviceID) {
    return await fetchWithHeaders(
      getBaseURL() + `/devices/${deviceID}/notifiers`,
      {
        method: "GET",
      }
    );
  }

  export async function addNewNotifier(deviceID, deviceData) {
    return await fetchWithHeaders(
      getBaseURL() + `/devices/${deviceID}/notifiers`,
      {
        method: "POST",
        body: deviceData,
      }
    );
  }

  export async function updateDeviceNotifier(deviceID, deviceData) {
    return await fetchWithHeaders(
      getBaseURL() + `/devices/${deviceID}/notifiers`,
      {
        method: "PUT",
        body: deviceData,
      }
    );
  }

  export async function deleteDeviceNotifier(deviceID, deviceData) {
    return await fetchWithHeaders(
      getBaseURL() + `/devices/${deviceID}/notifiers`,
      {
        method: "DELETE",
        body: deviceData,
      }
    );
  }

  export async function getDeviceVibrationMeasurementsByDate(
    deviceID,
    vibrationType,
    measurementDate
  ) {
    const url = new URL(getBaseURL() + `/devices/${deviceID}/measurements/series/vibration`);
    url.searchParams.set("vibrationType", vibrationType);
    url.searchParams.set("measurementDate", measurementDate);
    return await fetchWithHeaders(url.toString(), {
      method: "GET",
    });
  }

  export async function getDeviceMeasurementTableData(deviceID, startDate = null, endDate = null) {
    const queryParams = {};
      if (startDate) {
          queryParams.startDate = startDate;
      }
      if (endDate) {
          queryParams.startDate = endDate;
      }
      return await fetchWithHeaders(querifiedURL(getBaseURL() + `/devices/${deviceID}/measurements/table`, queryParams));
  }

  export async function getDeviceMeasurementSamples(deviceID) {
    const url = new URL(getBaseURL() + `/devices/${deviceID}/measurements/sampling`);
    return await fetchWithHeaders(url.toString(), {
      method: "GET",
    });
  }

  export async function createDeviceMeasurementSamples(deviceID, startDate = null, endDate = null) {
    const url = new URL(getBaseURL() + `/devices/${deviceID}/measurements/sampling`);
    return await fetchWithHeaders(url.toString(), {
      method: "POST",
      body: {
        startDate,
        endDate
      }
    });
  }

  export async function getDeviceAverageComparisons() {
    const url = new URL(getBaseURL() + `/devices/calculate-average-changes`);
    return await fetchWithHeaders(url.toString(), {
      method: "GET",
    });
  }
</script>
