<script>
  import { onMount } from "svelte";
  import ZoomableLineChart from "../../components/Chart/ZoomableLineChart.svelte";
  import {
    getDeviceMeasurementSeriesByType,
    getDeviceMeasurementSamples
  } from "../../helpers/apiHelpers/device-management.rest.svelte";

  export let deviceID = null;
  export let alarmValues = null;
  let measurementSamples = false;

  onMount(async () => {
    measurementSamples = await getDeviceMeasurementSamples(deviceID);
  });

  const getWetSeries = async () => {
    const data = await getDeviceMeasurementSeriesByType(deviceID, 'wet');
    return data.series;
  }
  const getTempSeries = async () => {
    const data = await getDeviceMeasurementSeriesByType(deviceID, 'temp');
    return data.series;
  }
  const getMeasurementSamplesForSeries = (measurementSamples, seriesName) => {
    if (!measurementSamples || !measurementSamples[seriesName]) {
      return null;
    }
    return measurementSamples[seriesName];
  }
  const xAxisFormatter = (stringDate) => {
    let tempDate = new Date(stringDate);
    return tempDate.toLocaleDateString('tr-TR') + ' ' + tempDate.toLocaleTimeString('tr-TR');
  }
</script>
<div>
  <div class="card-header">
    <h4 class="card-title">Nem Grafiği</h4>
  </div>
  {#key alarmValues?.wet_alarm }
    <ZoomableLineChart
      xAxisFormetter={xAxisFormatter}
      queryFn={getWetSeries}
      constData={alarmValues?.wet_alarm}
      constDataTitle="Alarm Değeri"
      lineColor="#008FFB"
      sampleColor="#FF0000"
      key='Nem'
    />
  {/key}
</div>
<div>
  <div class="card-header">
    <h4 class="card-title">Sıcaklık Grafiği</h4>
  </div>
  {#key alarmValues?.temp_alarm}
    <ZoomableLineChart
      xAxisFormetter={xAxisFormatter}
      queryFn={getTempSeries}
      constData={alarmValues?.temp_alarm}
      constDataTitle="Alarm Değeri"
      lineColor="#FF9800"
      sampleColor="#FF0000"
      key='Sıcaklık'
    />
  {/key}
</div>
