<script>
  import { navigate } from "svelte-navigator";
  import { removeCookie } from "../../helpers/utils.svelte";
  import { translator, userStore } from "../../store";
  export let src = "";
  export let userName = "";
  export let title = "";

  let visible = false;
  let ref = null;

  const toggle = () => {
    visible = !visible;
  };

  $: if (visible) {
    const children = [...(ref?.children || [])];
    children.forEach((child) => {
      if (!child.classList.contains("dropdown-item")) {
        child.className = "dropdown-item";
      }
    });
  }

  const onLogoutClick = () => {
    removeCookie("guessfault_uc");
    $userStore = {};
    navigate('/');
  };
</script>

<ul class="navbar-nav header-right">
  <li class="nav-item dropdown header-profile">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <span on:click={toggle} class="nav-link" role="button">
      <img style="object-fit: contain;" {src} alt="" />
      <div class="header-info ms-3">
        <span>{userName}</span>
        <small>{title}</small>
      </div>
    </span>
    {#if visible}
      <button
        on:click={onLogoutClick}
        class="dropdown-menu show d-flex justify-content-center align-items-center"
        style="margin: 16px 0px 0px 0px; position: absolute; inset: 0px auto auto 0px; transform: translate3d(0px, 51.5px, 0px); right: 0px;"
      >
        <i style="margin-right: 8px;" class="flaticon-381-exit-1" />
        {translator('Çıkış Yap')}
      </button>
    {/if}
  </li>
</ul>
