<script>
  export let configuration = null;
  let activeTab = Object.keys(configuration)[0];

  const setActiveTab = (e) => {
    activeTab = e.target.dataset.tabkey;
    console.log(activeTab);
  }

</script>
<style>
  .activeTabCustom {
    background-color: white !important;
    border-bottom-color: white !important;
    color: #ed6464 !important;
    font-weight: bold !important;
  }
</style>
<ul class="nav nav-tabs">
  {#each Object.keys(configuration) as tabKey}
    <li class="nav-item">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span
        style="cursor: pointer"
        on:click={setActiveTab}
        data-tabkey={tabKey}
        class="nav-link"
        class:active={tabKey == activeTab}
        class:activeTabCustom={tabKey == activeTab}>
        {configuration[tabKey].tabTitle}
      </span>
    </li>
  {/each}
</ul>
<div class="tab-content">
  {#key activeTab}
  <svelte:component
    this={configuration[activeTab].component}
    {...configuration[activeTab].componentProps}
  />
  {/key}
</div>
