<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function getUsers() {
      return await fetchWithHeaders(getBaseURL() + '/users');
    }

    export async function getUser(userID) {
      return await fetchWithHeaders(getBaseURL() + `/users/${userID}`);
    }

    export async function createUser(userData) {
      return await fetchWithHeaders(getBaseURL() + '/users', {
        method: 'POST',
        body: userData
      });
    }

    export async function updateUser(userID, userData) {
      return await fetchWithHeaders(getBaseURL() + `/users/${userID}/update`, {
        method: 'POST',
        body: userData
      });
    }

    export async function deleteUser(userID) {
      return await fetchWithHeaders(getBaseURL() + `/users/${userID}`, {
        method: 'DELETE'
      });
    }

    export async function sendForgotPasswordEmail(resetData) {
      return await fetchWithHeaders(getBaseURL() + '/forgot-password', {
            method: 'POST',
            body: resetData
      });
    }

</script>
