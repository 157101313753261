<script context="module">
  import { fetchWithHeaders, getBaseURL } from "../utils.svelte";

  export async function login(id_field, rawPassword) {
    const apiResp = await fetchWithHeaders(getBaseURL() + "/login", {
      method: "POST",
      body: JSON.stringify({ id_field, password: rawPassword }),
    });
    return apiResp;
  }

  export async function getLoggedInUser() {
    try {
      const apiResp = await fetchWithHeaders(getBaseURL() + "/user");
      return apiResp;
    } catch (err) {
      if (window.location.pathname.length > 1) {
        window.location.href = "/";
        return;
      }
      throw err;
    }
  }
</script>
