import jQuery from 'jquery';
import PerfectScrollbar from 'perfect-scrollbar';

var innap = function () {

  var handlePreloader = function () {
    setTimeout(function () {
      jQuery('#preloader').remove();
      jQuery('#main-wrapper').addClass('show');
    }, 800);

  }

  var handleMinHeight = function () {
    var win_h = window.outerHeight;
    var win_h = window.outerHeight;
    if (win_h > 0 ? win_h : screen.height) {
      jQuery(".content-body").css("min-height", (win_h + 60) + "px");
    };
  }

  var handleHeaderHight = function () {
    const headerHight = jQuery('.header').innerHeight();
    jQuery(window).scroll(function () {
      if (jQuery('body').attr('data-layout') === "horizontal" && jQuery('body').attr('data-header-position') === "static" && jQuery('body').attr('data-sidebar-position') === "fixed")
        jQuery(this.window).scrollTop() >= headerHight ? jQuery('.deznav').addClass('fixed') : jQuery('.deznav').removeClass('fixed')
    });
  }

  var handlePerfectScrollbar = function () {
    if (jQuery('.deznav-scroll').length > 0) {
      //const qs = new PerfectScrollbar('.deznav-scroll');
      const qs = new PerfectScrollbar('.deznav-scroll');

      qs.isRtl = false;
    }
  }

  /* Function ============ */
  return {
    init: function () {
      handleMinHeight();
      handleHeaderHight();
      handlePerfectScrollbar();
    },


    load: function () {
      handlePreloader();
    },
  }

}();

/* Window Load START */
// window.$ = jQuery;
window.jQuery = jQuery;
jQuery(window).on('load', function () {
  'use strict';
  innap.init();
  innap.load();
});

