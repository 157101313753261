<script>
  import { Button, Modal } from "../../../components/index.svelte";
  import { toast } from "../../../helpers/utils.svelte";
  import { translator } from "../../../store";

  let fileDetails;
  export let onModalClose;
  export let onDelete;
  export function modalToggleWrapper(fileToBeRemoved) {
    fileDetails = fileToBeRemoved;
    toggler();
  }

  let toggler;

  async function onDeleteClick() {
    try {
      // TODO :: send delete request to remote
      // fileDetails.id
      toast.success(translator("Başarılı"), translator("Dosya silindi"));
      if (typeof onDelete === 'function') {
        onDelete(fileDetails);
      }
      modalToggleWrapper(null);
    } catch (err) {
      toast.error(
        translator("Hata"),
        translator(
          err.getResponseMessage ? err.getResponseMessage() : err.message
        )
      );
    }
  }
  $: {
    fileDetails = fileDetails;
  }
</script>

<Modal
  bind:toggle={toggler}
  title={translator("Dikkat!")}
  onClose={onModalClose}
>
  <div slot="modal-body">
    {translator('"__FILENAME__" dosyasını kalıcı olarak silmek istediğinize emin misiniz?', {
      __FILENAME__: fileDetails.fileName
    })}
  </div>
  <div slot="modal-footer">
    <Button size="sm" onClick={onDeleteClick}>
      {translator("Sil")}
    </Button>
    <Button size="sm" onClick={toggler} colorCls="btn-dark">
      {translator("Vazgeç")}
    </Button>
  </div>
</Modal>
