<script context="module">
  export const MONTHS = {
    1: "Ocak",
    2: "Şubat",
    3: "Mart",
    4: "Nisan",
    5: "Mayıs",
    6: "Haziran",
    7: "Temmuz",
    8: "Ağustos",
    9: "Eylül",
    10: "Ekim",
    11: "Kasım",
    12: "Aralık",
  };

  export const USER_TYPES = {
    SYSTEM_ADMIN: 'System Admin',
    SYSTEM_USER: 'System User',
    COMPANY_ADMIN: 'Company Admin',
    COMPANY_USER: 'Company User'
  }
</script>
