<script context="module">
  import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

  export async function getUnresolvedNotifications() {
      return await fetchWithHeaders(getBaseURL() + '/user-unresolved-notifications');
  };

  export async function resolveNotification(notificationID) {
      return await fetchWithHeaders(getBaseURL() + '/resolve-notification/' + notificationID);
  };

</script>
