<script>
  import { navigate } from 'svelte-navigator';
  import { login } from '../../helpers/apiHelpers/index.svelte';
  import { setCookie, toast } from '../../helpers/utils.svelte';
  import { translator } from '../../store';
  import { userStore } from '../../store/user.store';


  async function loginFormSubmit(e) {
    try {
      const loginFormData = new FormData(e.target);
      const loginResp = await login(loginFormData.get("id_field"), loginFormData.get("password"));

      const apiKey = loginResp?.api_key;
      if (apiKey) {
        setCookie("guessfault_uc", apiKey, loginFormData.get("remember_creds") !== 'on');
        $userStore.cookie = apiKey;
        navigate('/');
      }
    } catch(err) {
      toast.error(translator("Hata"), translator(
        err.getResponseMessage ? err.getResponseMessage() : err.message
      ));
      console.log(err);
    }
  }

  function onForgotPasswordClick() {
    navigate('/forgot-password');
  }
</script>

<form id="login-form" on:submit|preventDefault={loginFormSubmit}>
  <div class="mb-3">
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <label class="mb-1"><strong>{translator('Kullanıcı Adı veya Email')}</strong></label>
    <input type="text" name="id_field" class="form-control" value="">
  </div>
  <div class="mb-3">
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <label class="mb-1"><strong>{translator('Şifre')}</strong></label>
    <input type="password" name="password" class="form-control">
  </div>
  <div class="row d-flex justify-content-between mt-4 mb-2">
    <div class="mb-3">
      <div class="form-check custom-checkbox ms-1">
        <input type="checkbox" name="remember_creds" class="form-check-input" id="remember_creds" value="on" checked="1">
        <label class="form-check-label" for="remember_creds">{translator('Remember my credentials')}</label>
      </div>
    </div>
    <div class="mb-3">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div on:click={onForgotPasswordClick} style="cursor: pointer;">{translator('Forgot Password?')}</div>
    </div>
  </div>
  <div class="text-center">
    <button type="submit" class="btn btn-primary btn-block">{translator('Sign In')}</button>
  </div>
</form>
