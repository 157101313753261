<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";
  
  import { locationStore, translator } from "../../store/index";
  import {
    deleteCompany,
    getCompanies,
  } from "../../helpers/apiHelpers/index.svelte";
  import { DataTable, Modal, Button } from "../../components/index.svelte";
  import { toast } from "../../helpers/utils.svelte";

  let dataSource = null;
  let toggler;
  let companyToDelete = null;

  function getColumns() {
    return [
      { data: "name", title: translator("Firma İsmi") },
      { data: "short_name", title: translator("Kısa İsim") },
      { data: "website", title: translator("Website") },
    ];
  }

  function getDataSource() {
    return getCompanies();
  }

  function onView(row) {
    console.log(row);
    // navigate(window.location.pathname + '/' + row.id);
  }

  function onEdit(row) {
    navigate(window.location.pathname + "/" + row.id + "/edit");
  }

  function onDelete(row) {
    companyToDelete = row;
    toggler();
  }

  async function onDeleteClick(companyID) {
    try {
      await deleteCompany(companyID);
      dataSource = await getDataSource();
      toast.success(
        translator("Başarılı"),
        translator('"__COMPANYNAME__" Firması silindi', {
          __COMPANYNAME__: `${companyToDelete.name}`,
        })
      );
      toggler();
    } catch (err) {
      console.log(err);
      toast.error(
        translator("Hata"),
        err.getResponseMessage ? err.getResponseMessage() : err.message
      );
    }
  }

  function onAdd() {
    navigate(window.location.pathname + "/new");
  }

  onMount(async () => {
    dataSource = await getDataSource();
  });
</script>

<div class="row">
  <DataTable
    title={translator("Firmalar")}
    eventHandlers={{
      onEdit,
      onDelete,
    }}
    columns={getColumns()}
    onAddClick={onAdd}
    addText={translator("Yeni Firma Oluştur")}
    bind:dataSource
  />
</div>
<Modal
  bind:toggle={toggler}
  title={translator("Dikkat!")}
  onClose={() => {
    companyToDelete = null;
  }}
>
  <div slot="modal-body">
    {translator(
      '"__COMPANYNAME__" Firmasını kalıcı olarak silmek istediğinize emin misiniz?',
      {
        __COMPANYNAME__: `${companyToDelete.name}`,
      }
    )}
  </div>
  <div slot="modal-footer">
    <Button
      size="sm"
      onClick={() => onDeleteClick(companyToDelete.id)}
    >
      {translator("Sil")}
    </Button>
    <Button size="sm" onClick={toggler} colorCls="btn-dark">
      {translator("Vazgeç")}
    </Button>
  </div>
</Modal>
