import { writable } from "svelte/store";

const createNotificationStore = () => {
    const { subscribe, set, update } = writable({
        toasts: []
    });

    return {
        subscribe,
        addToast: toast => update(s => {
            s.toasts.push(toast);
            return s;
        }),
        reset: () => set({
            toasts: []
        })
    }
};

export default createNotificationStore();