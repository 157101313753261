<script>
  import { onMount } from "svelte";
  import { translator } from "../../../store";
  import Button from "../../Button/index.svelte";
  import IconButton from "../../IconButton/index.svelte";
  import FileDeleteModal from "./FileDeleteModal.svelte";

  export let fieldName;
  export let label;
  export let required;
  export let multiple = true;
  export let selectedFiles = [];
  export let isViewMode = false;

  let inputID = Math.random().toString(36).slice(2, 12);
  let filePreviews = null;
  let fileInputRef;
  let openDeleteModal;

  function generateFileID() {
    return "file-" + Math.random().toString(36).slice(2, 12);
  }

  onMount(() => {});

  function onFileSelection(e) {
    const { files } = e.target;
    selectedFiles = [
      ...selectedFiles,
      ...Array.from(files).map((i1) => {
        i1.id = generateFileID();
        return i1;
      }),
    ];
  }

  function getMimeIcon(mimeType) {
    return "";
  }

  function generatePreviewLinks(fileList) {
    let previews = [];
    if (fileList?.length > 0) {
      Array.from(fileList).forEach((f1) => {
        if (f1 instanceof File) {
          let tempURL = URL.createObjectURL(f1);
          previews.push({
            id: f1.id,
            fileName: f1.name,
            url: tempURL,
            type: f1.type,
            thumbnail:
              f1.type && f1.type.indexOf("image") === 0
                ? tempURL
                : getMimeIcon(f1.type),
            isLocal: true,
          });
        } else {
          // To support initial files
          previews.push({
            id: f1.id,
            fileName: f1.file_name,
            url: f1.url,
            type: f1.mime_type,
            thumbnail:
              f1.mime_type && f1.mime_type.indexOf("image") === 0
                ? f1.url
                : getMimeIcon(f1.mime_type),
            isLocal: false,
          });
        }
      });
    }
    return previews;
  }

  function onUploadClick() {
    fileInputRef.click();
  }

  function removeFile(filePreview) {
    if (filePreview) {
      openDeleteModal(filePreview);
    }
  }

  function cleanupDeletedFile(deletedFile) {
    selectedFiles = selectedFiles.filter((i1) => i1.id != deletedFile.id);
    fileInputRef.value = "";
  }

  $: {
    filePreviews = generatePreviewLinks(selectedFiles);
  }
</script>

<div>
  {#if label}
    <label class="form-label" for={inputID}>
      {label}
      {#if required}
        <span class="text-red">*</span>
      {/if}
    </label>
  {/if}
  {#if !isViewMode}
    <Button
      disabled={!multiple && selectedFiles?.length === 1}
      colorCls="btn-info"
      fullWidth={true}
      onClick={onUploadClick}
    >
      <span class="btn-icon">
        <i class="fa fa-upload" />
      </span>
      {translator("Dosya Seç")}
    </Button>
    <input
      bind:this={fileInputRef}
      style="width: 0px;height: 0px;"
      id={inputID}
      name={fieldName}
      type="file"
      {multiple}
      on:change={onFileSelection}
    />
  {/if}
  <div>
    {#if filePreviews?.length > 0}
      <div class="table-responsive" style="max-height: 250px; overflow: auto;">
        <table
          class="table table-striped verticle-middle table-responsive-sm text-center"
        >
          <tbody style="border: 0px 0px !important">
            {#each filePreviews as filePreview}
              <tr style="height: 64px;background-color: #E0E0">
                <td>
                  <img
                    src={filePreview.thumbnail || '/assets/images/file_placeholder.png'}
                    style="max-width: 128px;max-height: 128px;width: 128px;height: auto;"
                    alt=""
                  />
                </td>
                <td class="text-dark">
                  {#if isViewMode}
                    <a rel="noreferrer" href={filePreview.url + '?forceDownload=1'} target="_blank" download>{filePreview.fileName}</a>
                  {:else}
                    {filePreview.fileName}
                  {/if}
                </td>
                {#if !isViewMode}
                  <td>
                    <IconButton
                      iconCls="fa fa-trash"
                      btnColorCls="bg-transparent"
                      textColorCls="text-red"
                      onClick={() => removeFile(filePreview)}
                    />
                  </td>
                {/if}
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    {/if}
  </div>
</div>
<FileDeleteModal
  onDelete={cleanupDeletedFile}
  bind:modalToggleWrapper={openDeleteModal}
/>
