<script>
  import { LoadingProvider } from "../../providers/index.svelte";
</script>

<div class="content-body">
  <LoadingProvider>
    <div class="container-fluid">
      <slot />
    </div>
  </LoadingProvider>
</div>
