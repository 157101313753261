import { writable } from "svelte/store";
import translate from "./translation.store"

const createLocationStore = () => {
    const { subscribe, set } = writable(translate("Dashboard"));

    return {
        subscribe,
        set: location => set(translate(location))
    }
};

export default createLocationStore();
