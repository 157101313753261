<script>
  import ForgotPassword from "./ForgotPassword.svelte";
  import Login from "./Login.svelte";

  export let formType = "login";
</script>
<div class="authincation h-100 d-flex flex-column justify-content-center align-items-center">
  <div class="container h-100 ">
    <div class="row justify-content-center h-100 align-items-center">
      <div class="col-md-6">
        <div class="authincation-content">
          <div class="row no-gutters">
            <div class="col-xl-12">
              <div class="auth-form">
                <div class="text-center mb-3">
                  <img class="w-75" src="/assets/images/app-logo.png" alt="">
                </div>
                {#if formType === "forgotPassword"}
                  <ForgotPassword />
                {:else}
                  <Login />
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
