<script>
  import { onMount } from "svelte";
  import { link } from "svelte-navigator";
  import { translator, userStore } from "../../store";
  import { checkIfAdmin, checkIfSuperAdmın } from "../../helpers/utils.svelte";

  import {
    handleMetisMenu,
    handleNavigation,
    handleCurrentActive,
    handleMiniSidebar,
    manageResponsiveSidebar,
  } from "./sidebar-initializer";

  function getRoutes() {
    let isAdmin = checkIfAdmin($userStore?.user?.user_type);
    let isSuperAdmin = checkIfSuperAdmın($userStore?.user?.user_type);
    const routes = [
      {
        path: "/",
        name: translator("Ana Sayfa"),
        iconClass: "flaticon-025-dashboard",
      },
      isAdmin ? {
        path: "/company-management/companies",
        name: translator("Firma Yönetimi"),
        iconClass: "flaticon-381-home",
      } : null,
      isAdmin ? {
        path: "/user-management/users",
        name: translator("Kullanıcı Yönetimi"),
        iconClass: "flaticon-381-user-9",
      } : null,
      {
        path: "/device-management/devices",
        name: translator("Cihaz Yönetimi"),
        iconClass: "flaticon-381-calendar-1",
      },
      {
        path: "/device-management/average-compare",
        name: translator("Ölçüm Karşılaştırmaları"),
        iconClass: "flaticon-381-repeat-1",
      },
    ];
    return routes.filter(i1 => !!i1);
  }

  onMount(() => {
    handleMetisMenu();
    handleNavigation();
    handleCurrentActive();
    handleMiniSidebar();
    manageResponsiveSidebar();
  });
</script>

<div class="deznav">
  <div class="deznav-scroll">
    <ul class="metismenu" id="menu">
      {#each getRoutes() as route}
        <li>
          {#if route.notLink}
            <!-- svelte-ignore a11y-missing-attribute -->
            <a
              style="font-size: 1rem;"
              role="button"
              class:has-arrow={route.subRoutes}
              class:ai-icon={route.subRoutes}
              aria-expanded="false"
            >
              <i class={route.iconClass} />
              <span class="nav-text">{route.name}</span>
            </a>
          {:else}
            <a
              role="button"
              style="font-size: 12px;padding:1rem"
              class:has-arrow={route.subRoutes}
              class:ai-icon={route.subRoutes}
              href={route.path}
              use:link
              aria-expanded="false"
            >
              <i class={route.iconClass} />
              <span class="nav-text">{route.name}</span>
            </a>
          {/if}
          {#if route.subRoutes}
            <ul aria-expanded="false">
              {#each route.subRoutes.filter((i1) => !!i1) as subRoute}
                <li>
                  <a href={route.path + subRoute.path} use:link
                    >{subRoute.name}</a
                  >
                </li>
              {/each}
            </ul>
          {/if}
        </li>
      {/each}
    </ul>
    <div class="copyright position-absolute bottom-0">
      <p>
        <strong>{translator("Guessfault Yönetim Paneli")}</strong> © 2023 {translator(
          "Tüm Hakları Saklıdır"
        )}
      </p>
    </div>
  </div>
</div>
