<script>
  import { translator, userStore } from './store';
  import { getCookie, toast } from './helpers/utils.svelte';
  import { getLoggedInUser } from './helpers/apiHelpers/index.svelte'
  import { onMount } from "svelte";
  import {
    ToastProvider,
    OuterLoadingProvider,
  } from "./providers/index.svelte";
  import AppRouter from './AppRouter.svelte';

  let isLoading = true;

  onMount(async () => {
    if (getCookie("guessfault_uc")) {
      $userStore.cookie = getCookie("guessfault_uc");
    } else {
      isLoading = false;
    }
  });

  $: if ($userStore?.cookie && !$userStore.isLoggedIn) {
    getLoggedInUser().then((userResponse) => {
      if (userResponse?.user) {
        $userStore = {
          ...$userStore,
          user: userResponse.user,
          isLoggedIn: true,
        };
      }
    }).catch((err) => {
      // Err is instance of RequestError
      toast.error(translator("Hata"), translator(err.getResponseMessage()));
    }).finally(() => {
      isLoading = false;
    });
  }
</script>

<ToastProvider />
<OuterLoadingProvider loading={isLoading} />
<div id="main-wrapper">
  <AppRouter />
</div>
