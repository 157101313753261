<script context="module">
  import { notification, translator } from "../store/index.js";

  const MONTHNAMES = {
    1: 'Ocak',
    2: 'Şubat',
    3: 'Mart',
    4: 'Nisan',
    5: 'Mayıs',
    6: 'Haziran',
    7: 'Temmuz',
    8: 'Ağustos',
    9: 'Eylül',
    10: 'Ekim',
    11: 'Kasım',
    12: 'Aralık'
  };

  export const CITIES_IN_TURKEY = [
    {
        city_code: "ADANA",
        city_name: "ADANA"
    },
    {
        city_code: "ADIYAMAN",
        city_name: "ADIYAMAN"
    },
    {
        city_code: "AFYONKARAHİSAR",
        city_name: "AFYONKARAHİSAR"
    },
    {
        city_code: "AĞRI",
        city_name: "AĞRI"
    },
    {
        city_code: "AMASYA",
        city_name: "AMASYA"
    },
    {
        city_code: "ANKARA",
        city_name: "ANKARA"
    },
    {
        city_code: "ANTALYA",
        city_name: "ANTALYA"
    },
    {
        city_code: "ARTVİN",
        city_name: "ARTVİN"
    },
    {
        city_code: "AYDIN",
        city_name: "AYDIN"
    },
    {
        city_code: "BALIKESİR",
        city_name: "BALIKESİR"
    },
    {
        city_code: "BİLECİKK",
        city_name: "BİLECİKK"
    },
    {
        city_code: "BİNGÖL",
        city_name: "BİNGÖL"
    },
    {
        city_code: "BİTLİS",
        city_name: "BİTLİS"
    },
    {
        city_code: "BOLU",
        city_name: "BOLU"
    },
    {
        city_code: "BURDUR",
        city_name: "BURDUR"
    },
    {
        city_code: "BURSA",
        city_name: "BURSA"
    },
    {
        city_code: "ÇANAKKALE",
        city_name: "ÇANAKKALE"
    },
    {
        city_code: "ÇANKIRI",
        city_name: "ÇANKIRI"
    },
    {
        city_code: "ÇORUM",
        city_name: "ÇORUM"
    },
    {
        city_code: "DENİZLİ",
        city_name: "DENİZLİ"
    },
    {
        city_code: "DİYARBAKIR",
        city_name: "DİYARBAKIR"
    },
    {
        city_code: "EDİRNE",
        city_name: "EDİRNE"
    },
    {
        city_code: "ELAZIĞ",
        city_name: "ELAZIĞ"
    },
    {
        city_code: "ERZİNCAN",
        city_name: "ERZİNCAN"
    },
    {
        city_code: "ERZURUM",
        city_name: "ERZURUM"
    },
    {
        city_code: "ESKİŞEHİR",
        city_name: "ESKİŞEHİR"
    },
    {
        city_code: "GAZİANTEP",
        city_name: "GAZİANTEP"
    },
    {
        city_code: "GİRESUN",
        city_name: "GİRESUN"
    },
    {
        city_code: "GÜMÜŞHANE",
        city_name: "GÜMÜŞHANE"
    },
    {
        city_code: "HAKKARİ",
        city_name: "HAKKARİ"
    },
    {
        city_code: "HATAY",
        city_name: "HATAY"
    },
    {
        city_code: "ISPARTA",
        city_name: "ISPARTA"
    },
    {
        city_code: "MERSİN",
        city_name: "MERSİN"
    },
    {
        city_code: "İSTANBUL",
        city_name: "İSTANBUL"
    },
    {
        city_code: "İZMİR",
        city_name: "İZMİR"
    },
    {
        city_code: "KARS",
        city_name: "KARS"
    },
    {
        city_code: "KASTAMONU",
        city_name: "KASTAMONU"
    },
    {
        city_code: "KAYSERİ",
        city_name: "KAYSERİ"
    },
    {
        city_code: "KIRKLARELİ",
        city_name: "KIRKLARELİ"
    },
    {
        city_code: "KIRŞEHİR",
        city_name: "KIRŞEHİR"
    },
    {
        city_code: "KOCAELİ",
        city_name: "KOCAELİ"
    },
    {
        city_code: "KONYA",
        city_name: "KONYA"
    },
    {
        city_code: "KÜTAHYA",
        city_name: "KÜTAHYA"
    },
    {
        city_code: "MALATYA",
        city_name: "MALATYA"
    },
    {
        city_code: "MANİSA",
        city_name: "MANİSA"
    },
    {
        city_code: "KAHRAMANMARAŞ",
        city_name: "KAHRAMANMARAŞ"
    },
    {
        city_code: "MARDİN",
        city_name: "MARDİN"
    },
    {
        city_code: "MUĞLA",
        city_name: "MUĞLA"
    },
    {
        city_code: "MUŞ",
        city_name: "MUŞ"
    },
    {
        city_code: "NEVŞEHİR",
        city_name: "NEVŞEHİR"
    },
    {
        city_code: "NİĞDE",
        city_name: "NİĞDE"
    },
    {
        city_code: "ORDU",
        city_name: "ORDU"
    },
    {
        city_code: "RİZE",
        city_name: "RİZE"
    },
    {
        city_code: "SAKARYA",
        city_name: "SAKARYA"
    },
    {
        city_code: "SAMSUN",
        city_name: "SAMSUN"
    },
    {
        city_code: "SİİRT",
        city_name: "SİİRT"
    },
    {
        city_code: "SİNOP",
        city_name: "SİNOP"
    },
    {
        city_code: "SİVAS",
        city_name: "SİVAS"
    },
    {
        city_code: "TEKİRDAĞ",
        city_name: "TEKİRDAĞ"
    },
    {
        city_code: "TOKAT",
        city_name: "TOKAT"
    },
    {
        city_code: "TRABZON",
        city_name: "TRABZON"
    },
    {
        city_code: "TUNCELİ",
        city_name: "TUNCELİ"
    },
    {
        city_code: "ŞANLIURFA",
        city_name: "ŞANLIURFA"
    },
    {
        city_code: "UŞAK",
        city_name: "UŞAK"
    },
    {
        city_code: "VAN",
        city_name: "VAN"
    },
    {
        city_code: "YOZGAT",
        city_name: "YOZGAT"
    },
    {
        city_code: "ZONGULDAK",
        city_name: "ZONGULDAK"
    },
    {
        city_code: "AKSARAY",
        city_name: "AKSARAY"
    },
    {
        city_code: "BAYBURT",
        city_name: "BAYBURT"
    },
    {
        city_code: "KARAMAN",
        city_name: "KARAMAN"
    },
    {
        city_code: "KIRIKKALE",
        city_name: "KIRIKKALE"
    },
    {
        city_code: "BATMAN",
        city_name: "BATMAN"
    },
    {
        city_code: "ŞIRNAK",
        city_name: "ŞIRNAK"
    },
    {
        city_code: "BARTIN",
        city_name: "BARTIN"
    },
    {
        city_code: "ARDAHAN",
        city_name: "ARDAHAN"
    },
    {
        city_code: "IĞDIR",
        city_name: "IĞDIR"
    },
    {
        city_code: "YALOVA",
        city_name: "YALOVA"
    },
    {
        city_code: "KARABüK",
        city_name: "KARABüK"
    },
    {
        city_code: "KİLİS",
        city_name: "KİLİS"
    },
    {
        city_code: "OSMANİYE",
        city_name: "OSMANİYE"
    },
    {
        city_code: "DÜZCE",
        city_name: "DÜZCE"
    }
  ]

  export function setCookie(cookieName, cookieData, sessionOnly = false) {
    const cookieToSave =
      cookieName +
      "=" +
      cookieData +
      ";" +
      (!sessionOnly ? 'expires=Thu, 31 Dec 2099 00:00:00 UTC; path="/"' : "");
    document.cookie = cookieToSave;
  }

  export function removeCookie(cookieName) {
    document.cookie = cookieName + "=; Max-Age=-99999999;";
  }

  export function getCookie(cookieName) {
    var cookies = document.cookie.split(";");
    if (cookies?.length > 0) {
      for (let cookieStr of cookies) {
        var cookieSelector = cookieName + "=";
        if (cookieStr.trim().startsWith(cookieSelector.trim())) {
          return cookieStr.replaceAll(cookieSelector, "");
        }
      }
    }
    return null;
  }

  const responseTypes = {
    "application/json": (response) => response.json(),
    "text/plain": (response) => response.text(),
  };
  // TODO: UPDATE FOR POST REQUESTS
  // const updateApiCache = async (url = '', method = '') => {
  //     if (method === 'POST')
  // }

  const cacheKeys = ["/user/", "system/predefined-values"];

  export class RequestError extends Error {
    constructor(message, response) {
      super(message);
      this.response = response;
    }

    getResponseMessage() {
      if (this.response && this.response.message) {
        return this.response.message;
      }
      return translator("Sistem Hatası");
    }
  }

  export async function fetchWithHeaders(...args) {
    const cacheKey = cacheKeys.filter((item) => args[0].includes(item))?.[0];
    if (cacheKey && window.apiCache?.[cacheKey]) {
      return window.apiCache[cacheKey];
    }
    const requestHeaders = {
      Authorization: getCookie("guessfault_uc"),
    };
    if (args[1]) {
      args[1].headers = requestHeaders;
    } else {
      args[1] = {
        headers: requestHeaders,
      };
    }
    let fetchResponse;
    let response;
    try {
      fetchResponse = await fetch(...args);
      if (fetchResponse) {
        const responseType = fetchResponse.headers.get("Content-Type");
        const responsePromise =
          responseTypes[responseType](fetchResponse) || fetchResponse;
        response = await responsePromise;
        if (
          !fetchResponse.ok &&
          (fetchResponse.status < 200 || fetchResponse.status > 399)
        ) {
          /* notification.modal({
                        title: 'Hata !',
                        message: response.message
                    }); */
          throw new Error(response?.message || "Request failed");
        }
        if (cacheKey) {
          window.apiCache = {
            ...window?.apiCache,
            [cacheKey]: response.content,
          };
        }
        return response.content;
      } else {
        throw new Error("Response body is empty");
      }
    } catch (err) {
      console.log(`API Error. URL: ${args[0]} `, err);
      // TODO :: May suppress errors;
      throw new RequestError(err, response);
    }
  }

  export const elementInViewPort = (element = null) => {
    if (element) {
      const bounding = element.getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= window.innerWidth &&
        bounding.bottom <= window.innerHeight
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  export function querifiedURL(url, params) {
    if (
      typeof params === "object" &&
      params &&
      Object.keys(params).length > 0
    ) {
      params = new URLSearchParams(params);
      return `${url}?${params}`;
    }
    return url;
  }

  export function getBaseURL() {
    let baseURL = "https://new-api.guessfault.com";
    return baseURL;
  }

  export function filesToFormData(files = []) {
    const formData = new FormData();
    for (const file of files) {
      formData.append("images", file);
    }
    return formData;
  }

  export function formatDate(date, format = "Y-m-d") {
    if (typeof date === "string") {
      date = dateInitializer(date);
    }
    let day = date.getDate().toString().padStart(2, "0");
    let month = date.getMonth() + 1;
    let monthInt = month;
    month = month.toString().padStart(2, "0");
    const year = date.getFullYear();

    switch (format) {
      case "d.m.Y":
        return `${day}.${month}.${year}`;
      case "MN Y":
        return `${translator(MONTHNAMES[monthInt])} ${year}`;
      case "d MN Y":
        return `${day} ${translator(MONTHNAMES[monthInt])} ${year}`;
      case "Y-m":
      return `${year}-${month}`;
      case "Y-md-d":
      default:
        return `${year}-${month}-${day}`;
    }
  }

  export const toast = {
    info: (title, message, timeOut) =>
      notification.addToast({ type: "info", title, message, timeOut }),
    success: (title, message, timeOut) =>
      notification.addToast({ type: "success", title, message, timeOut }),
    error: (title, message, timeOut) =>
      notification.addToast({ type: "error", title, message, timeOut }),
  };

  export function clickOutside(node) {
    const handleClick = (event) => {
      if (node && !node.contains(event.target) && !event.defaultPrevented) {
        node.dispatchEvent(new CustomEvent("clickOutside", node));
      }
    };

    document.addEventListener("click", handleClick, true);

    return {
      destroy() {
        document.removeEventListener("click", handleClick, true);
      },
    };
  }

  export const PPPlaceholder= "/assets/images/pp_placeholder.png";

  export function dateInitializer(...args) {
    var localDate = new Date(...args);
    var tzOffset = localDate.getTimezoneOffset() / 60;
    if (tzOffset < 0) {
      tzOffset = tzOffset * -1;
    }
    localDate.setTime(localDate.getTime() + (tzOffset * 60 * 60 * 1000))
    return localDate;
  }

  export function checkIfAdmin(user_type) {
    if (user_type === "ADMIN" || user_type === "SYSTEM_ADMIN") {
      return true;
    }
    return false;
  }

  export function isUserType(user_type, required) {
    if (required === user_type) {
      return true;
    }
    return false;
  }

  export function checkIfSuperAdmın(user_type) {
    if (user_type === "SYSTEM_ADMIN") {
      return true;
    }
    return false;
  }

  export const get = (obj, string, defaultValue) => {
    const parts = string.split(/[\]\[\.]/);
    if (!obj) return defaultValue;
    let value = obj;
    parts.forEach(part => {
      if (!value[part]) return defaultValue;
      value = value[part];
    });
    return value;
  }

</script>
